import { useState, useEffect, useRef } from "react"
import TableDataCell from "./TableDataCell"
import Input from "../input/Input"
import Select from "../select/Select"
import MultiSelect from "../multiSelect/MultiSelect"
import SimpleModal from "../SimpleModal/SimpleModal"

function TableDataRow({
  headers_array,
  data,
  entity_name,
  fn_select_row,
  fn_delete_row,
  fn_update_row,
  input_props_array,
  parent_key_header,
  children_key_headers,
  fn_disable_children,
  fn_new_children_values,
  disabled,
}) {
  const [rowDataObject, setRowDataObject] = useState({})
  const [selectedRow, setSelectedRow] = useState(false)

  const [newRowDataObject, setNewRowDataObject] = useState({})
  const [inputMode, setInputMode] = useState(false)

  // validation for the row
  const [onValidation, setOnValidation] = useState(true)
  const [validationObject, setValidationObject] = useState({})

  // diable related inputs
  const [isDisabled, setIsDisabled] = useState(false)
  // const [childrenKeyHeaders, setChildrenKeyHeaders] = useState([])

  const delete_ref = useRef(null)

  // fn_disable_children( rowDataObject[parent_key_header])
  // pass value of the parent_key_header to the function, and the function will return boolean value
  // if true, disable children inputs, else enable children inputs

  useEffect(() => {
    if (data.row_data) {
      setRowDataObject(data.row_data)
      setNewRowDataObject(data.row_data)
    }
    if (data.is_selected) {
      setSelectedRow(true)
    } else {
      setSelectedRow(false)
    }
  }, [data])

  // useEffect(() => {
  //   if (children_key_headers.length > 0) {
  //     setChildrenKeyHeaders(children_key_headers)
  //   }
  // }, [children_key_headers])

  /**
   * input component flow
   *
   * changing the input_component's value updates the rowDataObject
   * -> re-renders and recreates the input component with the updated rowDataObject's values
   * -> inputMode is true, so the new input component is rendered with the new values
   */

  useEffect(() => {
    if (parent_key_header && newRowDataObject[parent_key_header]) {
      let result = fn_disable_children(newRowDataObject[parent_key_header])
      setIsDisabled(result)
    }
  }, [newRowDataObject])

  //=======================================================
  let input_components = {}

  for (let i = 0; i < input_props_array.length; i++) {
    let input_props = input_props_array[i]
    let is_child = false

    if (children_key_headers && children_key_headers.length > 0) {
      is_child = children_key_headers.includes(input_props.header_key)
    }

    input_components = {
      ...input_components,
      [input_props.header_key]: (
        <>
          {input_props.input_type === "text" ? (
            <Input
              type='text'
              name={input_props.header_key}
              onChange={onChangeHandler}
              value={newRowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "number" ? (
            <Input
              type='number'
              name={input_props.header_key}
              onChange={onChangeHandler}
              value={newRowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "select" ? (
            <Select
              name={input_props.header_key}
              options={input_props.options}
              onChange={onChangeHandler}
              value={newRowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              addNewOption={input_props.add_new_option}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "multi-select" ? (
            <MultiSelect
              name={input_props.header_key}
              options={input_props.options}
              onChange={onChangeHandler}
              value={newRowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : null}
        </>
      ),
    }
  }

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled.
    // If false, there are required fields that are not filled.
    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  // select row function
  function selectRowHandler() {
    if (fn_select_row) {
      fn_select_row(newRowDataObject.row_index)
    }
  }

  function openDeleteModalHandler() {
    delete_ref.current.showModal()
  }

  function deleteRowHandler() {
    // pass value of key_header to parent
    if (fn_delete_row) {
      fn_delete_row(newRowDataObject.row_index)
    }
  }

  function saveRowDataHandler(new_row_data) {
    setOnValidation(true)
    if (hasRequiredFields()) {
      fn_update_row(new_row_data)
      setRowDataObject(new_row_data)
      setInputMode(false)
    } else {
      console.log("Missing required fields")
    }
  }

  function cancelRowHandler() {
    setNewRowDataObject(rowDataObject)
    setInputMode(false)
  }

  function onChangeHandler(newValue) {
    if (parent_key_header && newValue.name === parent_key_header) {
      let result = fn_disable_children(newValue.value)
      if (result) {
        if (fn_new_children_values) {
          let new_children_values = fn_new_children_values(newValue.value)
          setNewRowDataObject((dataObject) => ({
            ...dataObject,
            ...new_children_values,
            [newValue.name]: newValue.value,
          }))
        } else {
          setNewRowDataObject((dataObject) => ({
            ...dataObject,
            [newValue.name]: newValue.value,
          }))
        }
      } else {
        setNewRowDataObject((dataObject) => ({
          ...dataObject,
          [newValue.name]: newValue.value,
        }))
      }
      setIsDisabled(result)
    } else {
      setNewRowDataObject((dataObject) => ({
        ...dataObject,
        [newValue.name]: newValue.value,
      }))
    }
  }

  return (
    <div
      className={`table__body_row ${
        disabled ? "table__body_row--disabled" : ""
      }`}
    >
      <SimpleModal
        modal_ref={delete_ref}
        content_title={`Delete ${entity_name ? entity_name : "row"}`}
        content_body={`Are you sure you want to delete this ${
          entity_name ? entity_name : "row"
        }?`}
        btn_confirm='Delete'
        btn_cancel='Cancel'
        btn_close={true}
        fn_confirm={deleteRowHandler}
      />
      {inputMode ? (
        <>
          {headers_array.map((header, index) => {
            return (
              <TableDataCell
                key={`${header.header_name}-${index}`}
                header_data={header}
                row_data={newRowDataObject}
                fn_update_row={saveRowDataHandler}
                fn_cancel_row={cancelRowHandler}
                fn_delete_row={cancelRowHandler}
                input_component={input_components[header.header_key]}
                input_mode={inputMode}
                fn_input_mode={setInputMode}
              />
            )
          })}
        </>
      ) : (
        <>
          {headers_array.map((header, index) => {
            return (
              <TableDataCell
                key={`${header.header_name}-${index}`}
                header_data={header}
                row_data={rowDataObject}
                is_row_selected={selectedRow}
                fn_select_row={selectRowHandler}
                fn_update_row={saveRowDataHandler}
                fn_cancel_row={cancelRowHandler}
                fn_delete_row={openDeleteModalHandler}
                input_component={input_components[header.header_key]}
                input_mode={inputMode}
                fn_input_mode={setInputMode}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

export default TableDataRow
