import { useEffect, useState } from "react"
import { PowerBIEmbed } from "powerbi-client-react"
import { models } from "powerbi-client"
import { msalInstancePowerBI } from "../../index.js"
import { PowerBiPermissionScopes } from "../../authConfig.js"
import {
  getPowerBiWorkspaces,
  getPowerBiReports,
  getPowerBiReport,
} from "./PowerBiService.js"
import {
  getSavedPowerBiUserReport,
  getPowerBIUserEmails,
  insertPowerBIUserReport,
  updatePowerBIUserReport,
  deletePowerBIUserReport,
} from "../../utils/HttpClient.js"
import { faFileCirclePlus, faFileSlash } from "@fortawesome/pro-light-svg-icons"
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons"
import { updatePowerBIUserEmail } from "../../utils/HttpClient.js"
import InstructionalText from "../global/components/InstructionalText/InstructionalText.js"
import Button from "../global/components/button/Button.js"
import Select from "../global/components/select/Select.js"
import "./powerbi_styles.css"

function PowerBI({ user_data, get_user_data }) {
  // report object
  const [report, setReport] = useState(null)

  // workspace and report selection
  const [selectedWorkspace, setSelectedWorkspace] = useState("")
  const [selectedReport, setSelectedReport] = useState("")

  // raw data array from Power BI REST API
  const [workspacesList, setWorkspacesList] = useState([])
  const [reportsList, setReportsList] = useState([])

  // flag for no reports saved
  const [hasNoReports, setHasNoReports] = useState(false)

  // options for select dropdowns
  const [workspacesOptions, setWorkspacesOptions] = useState([
    "Select workspace",
  ])
  const [reportsOptions, setReportsOptions] = useState(["Select report"])

  // power bi response token for embedding and rest api calls
  const [pbiLoginResponse, setPbiLoginResponse] = useState("")

  // default embed config
  const [embedConfig, setEmbedConfig] = useState({
    type: "report",
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Aad,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth,
      },
    },
  })

  const [showInstructions, setShowInstructions] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [hasPowerBIScope, setHasPowerBIScope] = useState(false)

  const [pbiUserEmails, setPbiUserEmails] = useState([])
  const [selectedPbiUserEmail, setSelectedPbiUserEmail] = useState("")

  const [selectedUserHasNoReports, setSelectedUserHasNoReports] =
    useState(false)

  const [selectedWorkspaceID, setSelectedWorkspaceID] = useState("")
  const [selectedReportID, setSelectedReportID] = useState("")

  const [currentReport, setCurrentReport] = useState("")
  const [currentWorkspace, setCurrentWorkspace] = useState("")

  /**
   * Issue with embedURL in embed config for Power BI Client React lib's dependency
   * https://github.com/microsoft/powerbi-client-react/issues/94
   */

  // get Power BI User Emails for admin or saved report for user when user_data is updated
  useEffect(() => {
    let pbi_access_token = sessionStorage.getItem("pbi_access_token")
    let pbi_user_email = sessionStorage.getItem("pbi_user_email")

    if (pbi_access_token && pbi_user_email) {
      setHasPowerBIScope(true)
      if (user_data.user_role === "admin") {
        getPowerBIUserEmails()
          .then((response) => {
            let pbi_user_emails = response.data
            let options_list = ["Select Power BI Account"].concat(
              pbi_user_emails.map((item) => item.pbi_user_email)
            )
            setPbiUserEmails(options_list)
          })
          .catch((error) => {
            console.error("No Power BI User Emails found")
          })
        getWorkspacesHandler(pbi_access_token)
      } else {
        getSavedReport(pbi_access_token, pbi_user_email)
      }
    } else {
      setHasPowerBIScope(false)
      setShowInstructions(true)
    }
  }, [user_data])

  /**
   * when pbiAccessToken is set, check user_data.pbi_user_email
   * if different from current user's pbi_user_email, update user_data.pbi_user_email
   * otherwise, if admin, get workspaces, otherwise get saved report
   */

  useEffect(() => {
    if (pbiLoginResponse) {
      let pbi_user_email = pbiLoginResponse.account.username
      let pbi_access_token = pbiLoginResponse.accessToken

      sessionStorage.setItem("pbi_user_email", pbi_user_email)
      sessionStorage.setItem("pbi_access_token", pbi_access_token)

      if (user_data.pbi_user_email !== pbi_user_email) {
        updatePowerBIUserEmail(pbi_user_email)
          .then((res) => {
            // if successful, get new user_data
            get_user_data()
          })
          .catch((error) => {
            console.error(error)
            alert("Error updating Power BI User Email")
          })
      } else {
        if (user_data.user_role === "admin") {
          getPowerBIUserEmails()
            .then((response) => {
              let pbi_user_emails = response.data
              let options_list = ["Select Power BI Account"].concat(
                pbi_user_emails.map((item) => item.pbi_user_email)
              )
              setPbiUserEmails(options_list)
            })
            .catch((error) => {
              console.error("No Power BI User Emails found")
            })
          getWorkspacesHandler(pbi_access_token)
        } else {
          getSavedReport(pbi_access_token, pbi_user_email)
        }
      }
    }
  }, [pbiLoginResponse])

  // event listeners for Power BI report
  useEffect(() => {
    report?.on("visualClicked", (event) => {
      console.log("visualClicked", event)
    })

    report?.on("selectionChanged", (event) => {
      console.log("selectionChanged", event)
    })

    report?.on("dataSelected", (event) => {
      console.log("dataSelected", event)
    })

    report?.on("commandTriggered", (event) => {
      console.log("commandTriggered", event)
    })
  }, [report])

  function powerBILoginHandler() {
    msalInstancePowerBI
      .loginPopup({
        scopes: PowerBiPermissionScopes,
        prompt: "select_account",
      })
      .then((response) => {
        setHasPowerBIScope(true)
        setPbiLoginResponse(response)
      })
      .catch((error) => {
        console.error(error)
        setHasPowerBIScope(false)
        setShowInstructions(true)
      })
  }

  function getSavedReport(access_token, pbi_user_email) {
    getSavedPowerBiUserReport(pbi_user_email)
      .then((res) => {
        if (res.data.error === "no saved reports") {
          setHasNoReports(true)
          setShowInstructions(true)
          console.error(res.data.msg)
        } else if (res.data.error === "no pbi license") {
          setHasPowerBIScope(false)
          setShowInstructions(true)
          console.error(res.data.msg)
        } else {
          let saved_pbi_user_reports = res.data
          // if user has saved reports, show the first report
          if (saved_pbi_user_reports.length > 0) {
            let retrieved_report = saved_pbi_user_reports[0]
            getPowerBiReport(
              access_token,
              retrieved_report.workspace_id,
              retrieved_report.report_id
            )
              .then((response) => {
                if (response) {
                  setEmbedConfig({
                    ...embedConfig,
                    id: response.id,
                    embedUrl: response.embed_url,
                    accessToken: access_token,
                  })
                  setShowContent(true)
                  setHasNoReports(false)
                } else {
                  setHasNoReports(true)
                  console.error(response.msg)
                }
              })
              .catch((error) => {
                setHasNoReports(true)
                console.error(error)
              })
          } else {
            setHasNoReports(true)
            console.error("No Power BI user reports found")
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function getWorkspacesHandler(access_token) {
    getPowerBiWorkspaces(access_token)
      .then((workspaces) => {
        if (workspaces.length > 0) {
          setWorkspacesList(workspaces)
          let options_list = ["Select workspace"].concat(
            workspaces.map((item) => item.name)
          )
          setWorkspacesOptions(options_list)
        } else {
          setWorkspacesOptions(["No workspaces found"])
          setSelectedWorkspace("No workspaces found")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function getReportsHandler(access_token, workspace_id) {
    getPowerBiReports(access_token, workspace_id)
      .then((reports) => {
        if (reports.length > 0) {
          setReportsList(reports)
          let options_list = ["Select report"].concat(
            reports.map((item) => item.name)
          )
          setReportsOptions(options_list)
        } else {
          setReportsOptions(["No reports found"])
          setSelectedReport("No reports found")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function onChangeSelectWorkspace(newValue) {
    let pbi_access_token = sessionStorage.getItem("pbi_access_token")
    let workspace_name = newValue.value
    let selected_workspace = workspacesList.find(
      (item) => item.name === workspace_name
    )
    if (selected_workspace) {
      setSelectedWorkspace(workspace_name)
      setSelectedWorkspaceID(selected_workspace.id)
      getReportsHandler(pbi_access_token, selected_workspace.id)
    } else {
      setSelectedWorkspace("Select workspace")
      setSelectedWorkspaceID("")
      setReportsList([])
      setReportsOptions(["Select report"])
      console.error("Workspace not found")
    }
    setSelectedReport("Select report")
    setSelectedReportID("")
  }

  function onChangeSelectReport(newValue) {
    let pbi_access_token = sessionStorage.getItem("pbi_access_token")
    let report_name = newValue.value
    let selected_report = reportsList.find((item) => item.name === report_name)
    if (selected_report) {
      setSelectedReport(report_name)
      setSelectedReportID(selected_report.id)
      setEmbedConfig({
        ...embedConfig,
        id: selected_report.id,
        embedUrl: selected_report.embedUrl,
        accessToken: pbi_access_token,
      })
      setShowContent(true)
    } else {
      setSelectedReport("Select report")
      setSelectedReportID("")
      console.error("Report id not found")
    }
  }

  function onChangeInputPbiUserEmail(newValue) {
    setSelectedPbiUserEmail(newValue.value)
  }

  // when pbi user email changes, get the saved reports for that user
  // if report exists update, otherwise insert
  useEffect(() => {
    if (
      selectedPbiUserEmail !== "Select Power BI Account" &&
      selectedPbiUserEmail !== ""
    ) {
      getSavedPowerBiUserReport(selectedPbiUserEmail)
        .then((res) => {
          if (res.data.error === "no saved reports") {
            setSelectedUserHasNoReports(true)
            setCurrentReport("")
            setCurrentWorkspace("")
          } else {
            setSelectedUserHasNoReports(false)
            setCurrentReport(res.data[0].report_name)
            setCurrentWorkspace(res.data[0].workspace_name)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setCurrentReport("")
      setCurrentWorkspace("")
    }
  }, [selectedPbiUserEmail, user_data])

  function TextBox({ headerText, bodyText }) {
    return (
      <div className='text_box'>
        <div className='text_box__header'>
          <p className='text_box__header__text'>{headerText}</p>
        </div>
        <div className='text_box__body'>
          <p className='text_box__body__text'>{bodyText}</p>
        </div>
      </div>
    )
  }

  function assignReportHandler() {
    if (selectedWorkspaceID !== "" && selectedReportID !== "") {
      if (selectedUserHasNoReports) {
        insertPowerBIUserReport(
          selectedPbiUserEmail,
          selectedWorkspaceID,
          selectedWorkspace,
          selectedReportID,
          selectedReport
        )
          .then((res) => {
            if (res.data?.success) {
              get_user_data()
            } else {
              console.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        updatePowerBIUserReport(
          selectedPbiUserEmail,
          selectedWorkspaceID,
          selectedWorkspace,
          selectedReportID,
          selectedReport
        )
          .then((res) => {
            if (res.data?.success) {
              get_user_data()
            } else {
              console.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  function deleteReportHandler() {
    if (currentWorkspace !== "" && currentReport !== "") {
      deletePowerBIUserReport(selectedPbiUserEmail)
        .then((res) => {
          if (res.data?.success) {
            get_user_data()
          } else {
            console.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return (
    <>
      <div className='powerbi_outer_container'>
        {hasPowerBIScope && user_data.user_role === "admin" && (
          <div className='powerbi_admin_menu'>
            <div className='powerbi_workspace_report_menu'>
              <TextBox
                headerText={"Admin Power BI Account"}
                bodyText={sessionStorage.getItem("pbi_user_email")}
              />
              <Select
                label={"Workspace"}
                name={"workspace"}
                value={selectedWorkspace}
                options={workspacesOptions}
                onChange={onChangeSelectWorkspace}
              />
              <Select
                label={"Report"}
                name={"report"}
                value={selectedReport}
                options={reportsOptions}
                onChange={onChangeSelectReport}
              />
              <div className='button_container'>
                <Button
                  buttonText={"Assign Report"}
                  className={"button__with__icon button__with__icon--primary"}
                  icon={faFileCirclePlus}
                  onClick={assignReportHandler}
                />
              </div>
            </div>
            <div className='powerbi_workspace_report_menu'>
              <Select
                label={"Client Power BI Account"}
                name={"pbi_user_email"}
                value={selectedPbiUserEmail}
                options={pbiUserEmails}
                onChange={onChangeInputPbiUserEmail}
                addNewOption={true}
              />
              <TextBox
                headerText={"Assigned Workspace"}
                bodyText={currentWorkspace}
              />
              <TextBox
                headerText={"Assigned Report"}
                bodyText={currentReport}
              />
              <div className='button_container'>
                <Button
                  buttonText='Delete Report'
                  className={"button__with__icon button__with__icon--primary"}
                  icon={faFileSlash}
                  onClick={deleteReportHandler}
                />
              </div>
            </div>
          </div>
        )}

        {showContent && hasPowerBIScope && (
          <PowerBIEmbed
            embedConfig={embedConfig}
            cssClassName={"powerbi_report_container"}
            getEmbeddedComponent={(embedObject) => {
              setReport(embedObject)
            }}
          />
        )}

        {!hasPowerBIScope && (
          <div className='powerbi_login_menu'>
            <InstructionalText
              type={"Main Instructions"}
              title={"Power BI License Required"}
              content={
                "Please login with your Power BI account to view Power BI Reports."
              }
              additionalContent={[
                "To learn more about Power BI licensing and Reports, contact us at info@sisaenergy.com.",
              ]}
            />
            <Button
              buttonText='Login with Power BI'
              icon={faMicrosoft}
              className={"button__with__icon button__with__icon--primary"}
              onClick={powerBILoginHandler}
            />
          </div>
        )}

        {/* No reports saved */}
        {showInstructions && hasPowerBIScope && hasNoReports && (
          <div className='powerbi_login_menu'>
            <InstructionalText
              type={"Main Instructions"}
              title={"Power BI Reports Not Found"}
              content={
                "Power BI account is verified, however there aren't any reports available for this account."
              }
              additionalContent={[
                "Please contact us at info@sisaenergy.com to view Power BI Reports.",
              ]}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default PowerBI
