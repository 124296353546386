import React, { useEffect, useState } from "react"
import Fade from "@mui/material/Fade"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import AnimateHeight from "react-animate-height"
import { NavLink } from "react-router-dom"
import "../../styles/table.css"
import {
  parseIntAndFormat,
  parseIntCurrencyRoundedFormat,
  parseFloatAndFormat,
} from "../../utils/DataFormatter"
import RemovePropertyDialog from "./components/RemovePropertyDialog"
import UpdatePropertyDialog from "./components/UpdatePropertyDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClipboardList } from "@fortawesome/pro-light-svg-icons"
import { useNavigate } from "react-router-dom"
import { formatNumberGrouping } from "../../utils/DataFormatter"

function TableRow(props) {
  /*
        This Component creates a table row for the home page table. It gives the user an option to expand the data to show upgrades for their building.
     */

  // Style the hover tooltip
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))

  // States:
  const [height, setHeight] = useState(0)
  const [tableData, setTableData] = useState([])
  const [expandedTableData, setExpandedTableData] = useState([])
  const [dataShown, setDataShown] = useState(false)
  const [order, setOrder] = useState("1DSC")

  const [tdElement, setTdElement] = useState("td-element")
  const [extendedTablePcRemover, setExtendedTablePcRemover] = useState(
    "ex-table-pc-remover"
  )
  const [extendedTableMobileRemover, setExtendedTableMobileRemover] = useState(
    "ex-table-mobile-remover"
  )
  const [isActive, setIsActive] = useState(false)
  const [expandedElement, setExpandedElement] = useState("expanded-element")
  const [isExpended, setIsExpended] = useState(false)
  const [newProjects, setNewProjects] = useState(null)
  const navigate = useNavigate()

  // Pull data from props and set the table data
  useEffect(() => {
    setTableData(props.data)
    setExpandedTableData(props.data[6])
    let new_projects_list = localStorage.getItem("new_projects_list")
    setNewProjects(new_projects_list)
  }, [props.data])
  // Sorting function handles sorting for the expanded ecm table
  const sorting = (col) => {
    if (order.substring(1) === "ASC") {
      const sorted = [...expandedTableData].sort((a, b) =>
        a[col].localeCompare(b[col], "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      setExpandedTableData(sorted)
      setOrder(col + "DSC")
    }

    if (order.substring(1) === "DSC") {
      const sorted = [...expandedTableData].sort((a, b) =>
        b[col].localeCompare(a[col], "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      setExpandedTableData(sorted)
      setOrder(col + "ASC")
    }
  }

  // Generate ECM data from the expandedTableData
  const generateTableData = () => {
    return expandedTableData.map((building) => (
      <tr className='row'>
        {building.map((data, index) => {
          // index 0 and 1 are not to be displayed, they are project number and site names
          if (index !== 0 && index !== 1) {
            if ([5, 6, 7].includes(index)) {
              // these indices are monetary
              return (
                <td
                  key={index}
                  className='expanded-table columnItem align-middle'
                >
                  <div className='table-headers'>
                    {props.headers[index - 2]}
                  </div>
                  <div>{parseIntCurrencyRoundedFormat(data)}</div>
                </td>
              )
            } else if (index == 3) {
              return (
                <td
                  key={index}
                  className='expanded-table columnItem align-middle'
                >
                  <div className='table-headers'>
                    {props.headers[index - 2]}
                  </div>
                  <div>{parseIntAndFormat(data)}</div>
                </td>
              )
            } else {
              return (
                <td
                  key={index}
                  className='expanded-table columnItem align-middle'
                >
                  <div className='table-headers'>
                    {props.headers[index - 2]}
                  </div>
                  <div>{data}</div>
                </td>
              )
            }
          }
        })}
      </tr>
    ))
  }

  // Renders the table headers along with sorting icons
  const renderTableHeaderRow = () => {
    return props.headers.map((value, index) => {
      return (
        <th
          id='thread-expanded-row'
          className='expanded-header sticky top-0'
          key={index}
        >
          <div className='flex justify-between items-center '>
            {value}
            <span
              className='material-symbols-outlined'
              onClick={() => sorting(index + 2)}
            >
              {order === index + 2 + "ASC" ? "expand_more" : "expand_less"}
            </span>
          </div>
        </th>
      )
    })
  }

  // Renders the expanded ECM table container
  function ExpandedData() {
    return (
      <div className={"" + expandedElement}>
        <div className='widget-header-expanded mt-3 '>
          <h2>
            ECMs for <i>{tableData[1]}</i>
          </h2>
        </div>
        <table id='expanded-ecm' className='items-center m-auto mb-5 '>
          <tr>{renderTableHeaderRow()}</tr>
          {generateTableData()}
        </table>
      </div>
    )
  }

  const fetchDecarbPlan = (id) => {
    localStorage.setItem(
      "selected_project_number",
      JSON.stringify(tableData[0])
    )
    navigate("/analysis/decarb")
  }

  return (
    <>
      {" "}
      {/* This renders the home page table row with an expanded ECM table inside */}
      <tr
        className='row columnItem align-middle'
        // style={height === "auto" ? { borderBottom: "0.1px solid #ddd" } : {}}
        key={props.index}
      >
        {tableData.map((data, index) => {
          if (index !== 0 && index !== 1 && index !== 6) {
            // Original code before the pilot
            // if ([4, 5, 6].includes(index)) {
            //   return (
            //     <td
            //       key={index}
            //       className={"columnItem align-middle " + tdElement}
            //     >
            //       <span className='table-headers'>
            //         {props.tableHeaders[index - 1]}
            //       </span>
            //       <span>{parseIntCurrencyRoundedFormat(data)}</span>
            //     </td>
            //   )
            // } else if (index === 7 || index === 8) {
            //   return (
            //     <td
            //       key={index}
            //       className={"columnItem  align-middle " + tdElement}
            //     >
            //       <span className='table-headers'>
            //         {props.tableHeaders[index - 1]}
            //       </span>
            //       <span>
            //         {index === 7
            //           ? parseIntAndFormat(data)
            //           : parseFloatAndFormat(data)}
            //       </span>
            //     </td>
            //   )
            // } else {
            let displayData = data

            if (data) {
              if (index === 2) {
                displayData = `${data} GJ/(m².yr)`
              } else if (index === 3) {
                displayData = `${data} Ton CO₂e/(m².yr)`
              } else if (index === 4) {
                displayData = `${formatNumberGrouping(data)} Ton CO₂e/yr`
              } else if (index === 5) {
                displayData = `${data}%`
              }
            } else {
              displayData = "-"
            }

            return (
              <td
                key={index}
                className={"columnItem  align-middle " + tdElement}
              >
                <span className='table-headers'>
                  {props.tableHeaders[index - 1]}
                </span>
                <span className='table-cell-text'>{displayData}</span>
              </td>
            )
            // }
          } else if (index === 1) {
            return (
              <>
                <td
                  id='td-first-element'
                  key={index}
                  className=' align-middle columnItem'
                >
                  <div className='table-headers float-left'>
                    {props.tableHeaders[index - 1]}
                  </div>
                  <div className='inline-block dash-name-align'>
                    {/* commented out for the expended table */}

                    {/* <LightTooltip
                      title='Show upgrade data'
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      enterDelay={600}
                      arrow
                    >
                      <span
                        id='table-decal-signs'
                        className='mr-3 mt-0.5 float-left w-1/12'
                        // onClick={() => setHeight(height === 0 ? "auto" : 0)}
                        onClick={() => [
                          setIsExpended(!isExpended),
                          setExpandedElement(
                            expandedElement === '' ? 'expanded-element' : ''
                          ),
                        ]}
                      >
                    {isExpended ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-dash-circle'
                            viewBox='0 0 16 16'
                          >
                            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                            <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-plus-circle'
                            viewBox='0 0 16 16'
                          >
                            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                          </svg>
                        )}
                    </span>
                    </LightTooltip> */}
                    {/* <p id='building-num' className='float-left w-9/12 building-name'> */}
                    <NavLink to={`/report/${tableData[0]}`}>
                      <div className='flex justify-start items-center'>
                        {newProjects && newProjects.includes(tableData[0]) ? (
                          <span className='material-symbols-outlined text-sky-600 pr-2'>
                            fiber_new
                          </span>
                        ) : null}
                        <p className='float-left w-9/12 building-name'>
                          {data}
                        </p>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    id='accordion-swtich'
                    className='material-symbols-outlined'
                    onClick={(e) => [
                      e.stopPropagation(),
                      setIsActive(!isActive),
                      setTdElement(!isActive ? "" : "td-element"),
                    ]}
                  >
                    {isActive ? "expand_more" : "expand_less"}
                  </div>
                </td>
                {/* This is responsible for rendering the expanded table (width less than 768px)*/}
                {expandedTableData ? (
                  <td
                    id='expanded-table-mobile'
                    colSpan='11'
                    style={{
                      marginRight: 20,
                      marginLeft: 20,
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <AnimateHeight id='example-panel' duration={500}>
                      {<ExpandedData />}
                    </AnimateHeight>
                  </td>
                ) : (
                  <></>
                )}
              </>
            )
          }
        })}
        {/* {
          <td
            key={"update-building"}
            className={"columnItem  align-middle " + tdElement}
          >
            <LightTooltip
              title='Update Building'
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              placement='bottom'
              arrow
            >
              <div>
                <UpdatePropertyDialog
                  building_name={tableData[1]}
                  project_number={tableData[0]}
                  fn_update_building={props.fn_update_building}
                  get_user_data={props.get_user_data}
                />
              </div>
            </LightTooltip>
          </td>
        } */}
        {
          // <td
          //   key={"view-report"}
          //   className={`columnItem  align-middle" + tdElement ${
          //     isActive ? "hide-on-mobile" : ""
          //   }`}
          // >
          <td
            key={"view-report"}
            className={`columnItem  align-middle ${
              isActive ? "hide-on-mobile" : ""
            } ${tdElement}`}
          >
            <LightTooltip
              title='View Decarb Plan'
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              placement='bottom'
              arrow
            >
              {/* <div> */}
              {/* <NavLink id='active-decal' to={`/report/${tableData[0]}`}> */}
              <div
                className='btn-conatiner decarb-container'
                onClick={fetchDecarbPlan}
              >
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className='decarb-font-awesome'
                />
                <span>View Decarb Plan</span>
                {/* <span className='material-symbols-outlined'>
                  open_in_browser
                </span> */}
              </div>
              {/* </div> */}
            </LightTooltip>
          </td>
        }
        {
          <td
            key={"remove-building"}
            className={"columnItem align-middle " + tdElement}
          >
            <LightTooltip
              title='Remove Building'
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              placement='bottom'
              arrow
            >
              <div>
                <RemovePropertyDialog
                  building_name={tableData[1]}
                  project_number={tableData[0]}
                  fn_remove_building={props.fn_remove_building}
                  get_user_data={props.get_user_data}
                />
              </div>
            </LightTooltip>
          </td>
        }
      </tr>
      {/* This is responsible for rendering the expanded table */}
      {expandedTableData ? (
        <td
          id='expanded-table-pc'
          colSpan='11'
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <AnimateHeight id='example-panel' duration={500}>
            {<ExpandedData />}
          </AnimateHeight>
        </td>
      ) : (
        <></>
      )}
    </>
  )
}

export default TableRow
