import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSquare,
  faPenToSquare,
  faSquareCheck,
  faCircleCheck,
  faBan,
  faTrash,
} from "@fortawesome/pro-light-svg-icons"

function TableDataCell({
  header_data,
  row_data,
  fn_select_row,
  fn_delete_row,
  fn_update_row,
  fn_cancel_row,
  fn_input_mode,
  is_row_selected,
  input_component,
  input_mode,
}) {
  // render input field / select field / object based on data type

  const [cellData, setCellData] = useState("")

  /**
   * - check input type and create the input component
   * - useEffect to set the inputData state to input_data
   *   - row_data = table data
   *   - input_data = new data object
   * - value of input_data is updated with fn_update_input_data
   * - pass the input_data as the value
   */

  useEffect(() => {
    if (row_data) {
      if (input_mode) {
        setCellData(input_component)
      } else {
        setCellData(row_data[header_data.header_key])
      }
    }
  }, [row_data, input_mode])

  // select row function
  function selectRowHandler() {
    if (fn_select_row) {
      fn_select_row(!is_row_selected)
    }
  }

  // delete row handler
  function deleteRowHandler() {
    // pass value of key_header to parent
    if (fn_delete_row) {
      fn_delete_row()
    }
  }

  function cancelRowHandler() {
    fn_cancel_row()
  }

  // edit row handler
  // also triggers editmode for components
  function editRowHandler() {
    fn_input_mode(!input_mode)
  }

  // save row data handler
  function saveRowDataHandler() {
    console.log("save row data", row_data)
    fn_update_row(row_data)
  }

  return (
    <>
      {header_data.header_key === "select_row" ? (
        <div className='table__body_row_cell_select'>
          <FontAwesomeIcon
            className='table__body_row_cell_select_icon'
            icon={is_row_selected ? faSquareCheck : faSquare}
            onClick={selectRowHandler}
          />
        </div>
      ) : header_data.header_key === "edit_row" ? (
        <div className='table__body_row_cell_edit'>
          <div className='table__body_row_cell_edit_buttons'>
            {input_mode ? (
              <>
                <FontAwesomeIcon
                  className='table__body_row_cell_edit_buttons--save'
                  icon={faCircleCheck}
                  onClick={saveRowDataHandler}
                />
                <FontAwesomeIcon
                  className='table__body_row_cell_edit_buttons--cancel'
                  icon={faBan}
                  onClick={cancelRowHandler}
                />
              </>
            ) : (
              <FontAwesomeIcon icon={faPenToSquare} onClick={editRowHandler} />
            )}
          </div>
        </div>
      ) : header_data.header_key === "delete_row" ? (
        <div className='table__body_row_cell_select'>
          {input_mode ? (
            <FontAwesomeIcon
              className='table__body_row_cell_select_icon'
              icon={faTrash}
              onClick={cancelRowHandler}
            />
          ) : (
            <FontAwesomeIcon
              className='table__body_row_cell_select_icon'
              icon={faTrash}
              onClick={deleteRowHandler}
            />
          )}
        </div>
      ) : (
        <div
          className={`table__body_row_cell_data ${
            header_data.cell_width
              ? `cell_width--${header_data.cell_width}`
              : ""
          }`}
        >
          <div className='table__body_row_cell_data_text'>{cellData}</div>
        </div>
      )}
    </>
  )
}
export default TableDataCell
