import { useEffect, useState } from "react"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import Select from "../../../global/components/select/Select"
import RadioGroup from "../../../global/components/RadioButton/RadioGroup"
import Input from "../../../global/components/input/Input"
import Textarea from "../../../global/components/textarea/Textarea"
import {
  ControlTypeHeatingCoolingList,
  CoolingTypeList,
} from "./EquipmentOptions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"

/**
 * @param {object} input_data - cooling data object
 * @param {function} fn_set_input_data - setter function for cooling data object
 * @param {object} options_list_obj - cooling options list object
 * @param {boolean} on_validation - validation flag
 * @param {function} fn_set_validation_obj - setter function for validation object
 * @returns {JSX.Element} - cooling specifications JSX Element
 */
function CoolingSpecs({
  input_data,
  fn_set_input_data,
  options_list_obj,
  on_validation,
  fn_set_validation_obj,
}) {
  // list of options for select components
  const { meters_list, units_list } = options_list_obj

  // end use data object
  const [endUseData, setEndUseData] = useState({})

  // open close details flag
  const [openDetails, setOpenDetails] = useState(true)

  useEffect(() => {
    if (input_data) {
      setEndUseData(input_data)
    }
  }, [input_data])

  function handleEndUseInputChange(newValue) {
    let end_use_name = endUseData.end_use_name
    let end_use_input_name = newValue.name
    let end_use_input_value = newValue.value

    if (end_use_input_name.includes(end_use_name)) {
      end_use_input_name = end_use_input_name.replace(end_use_name, "").slice(1)
    }
    let new_end_use_data = {
      ...endUseData,
      [end_use_input_name]: end_use_input_value,
    }
    fn_set_input_data({
      type: "update_end_use",
      payload: new_end_use_data,
    })
  }

  function handleOpenDetails() {
    setOpenDetails(!openDetails)
  }

  return (
    <div id='cooling-unit-specification'>
      <hr />
      {openDetails ? (
        <>
          <InstructionalText
            type='Main Instructions'
            title={`${endUseData.end_use_name} - Cooling Specifications`}
            content={
              <>
                <p>
                  Use this section to provide specifics about any cooling
                  equipment. Include multiple cooling sections if there are
                  distinct end-uses. For instance, if a central chiller serves
                  "Space Cooling," and "Outdoor Air Cooling,", create separate
                  sections for each.
                </p>
                <p>
                  If there are multiple cooling sections and specific capacities
                  are allocated through mechanisms like heat exchangers, assign
                  the designated capacity to each section accordingly. However,
                  if there's no explicit allocation, utilize the total available
                  capacity of the main system for all cooling sections.
                </p>
              </>
            }
            containerClassName='main-instructions'
          />
          <div className='content part-1'>
            {/* <Select
          label='End-use'
          name='end_use'
          options={endUseList}
          required
          placeholder='Select end-use'
          onChange={handleEndUseInputChange}
          value={input_data.end_use}
        /> */}
            <Select
              label='Cooling Type'
              name='cooling_unit_type'
              options={CoolingTypeList}
              onChange={handleEndUseInputChange}
              value={endUseData.cooling_unit_type}
              placeholder='Select cooling unit type'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <RadioGroup
              legend='Is this a Primary or a Secondary Unit?'
              name={`${endUseData.end_use_name}_primary_or_secondary_unit`}
              radioOptions={[
                {
                  value: "Primary",
                  label: "Primary",
                },
                {
                  value: "Secondary",
                  label: "Secondary",
                },
              ]}
              onChange={handleEndUseInputChange}
              value={endUseData.primary_or_secondary_unit}
            />
            <Input
              label='Capacity Rating'
              type='text'
              name='capacity_rating'
              onChange={handleEndUseInputChange}
              value={endUseData.capacity_rating}
              placeholder='Enter capacity rating'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Unit'
              name='unit'
              options={units_list}
              onChange={handleEndUseInputChange}
              value={endUseData.unit}
              placeholder='Select unit'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Meter'
              name='meter'
              options={meters_list}
              onChange={handleEndUseInputChange}
              value={endUseData.meter}
              placeholder='Select meter'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div className='content'>
            <Input
              label='Average Efficiency/COP'
              type='text'
              name='average_efficiency_or_cop'
              onChange={handleEndUseInputChange}
              value={endUseData.average_efficiency_or_cop}
              placeholder='Enter average efficiency or COP'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Control Type'
              name='control_type'
              options={ControlTypeHeatingCoolingList}
              onChange={handleEndUseInputChange}
              value={endUseData.control_type}
              placeholder='Select control type'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Input
              label='Average Annual Hours of Operation'
              type='number'
              name='average_annual_hours_of_operation'
              onChange={handleEndUseInputChange}
              value={endUseData.average_annual_hours_of_operation}
              placeholder='(0 to 8760 hrs/yr)'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div className='content'>
            <div className='general-comments'>
              <Textarea
                label='General Comments'
                type='text'
                name='general_comments'
                onChange={handleEndUseInputChange}
                value={endUseData.general_comments}
                placeholder='Enter your general comments here, e.g., Comments on control...'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='content'>
            <div className='flex justify-end'>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon={faChevronUp} />
                <p className='end-use-details-button-text'>Hide Details</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='content'>
            <div className='flex justify-between'>
              <h2 className='end-use-title'>
                {`${endUseData.end_use_name} - Cooling Specifications`}
              </h2>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon={faChevronDown} />
                <p className='end-use-details-button-text'>Show Details</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default CoolingSpecs
