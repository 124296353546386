import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSort,
  faSortUp,
  faSortDown,
  faSquare,
  faTrash,
  faPenToSquare,
} from "@fortawesome/pro-light-svg-icons"
import { useState, useEffect } from "react"

function TableHeader({
  header_data,
  fn_sort_by_header,
  fn_select_all,
  sort_order,
}) {
  const [headerName, setHeaderName] = useState("")

  useEffect(() => {
    if (header_data.header_name) {
      if (header_data.is_required) {
        setHeaderName(`${header_data.header_name}*`)
      } else {
        setHeaderName(header_data.header_name)
      }
    }
  }, [header_data])

  // sort by header name
  function sortHandler() {
    console.log(header_data)
    if (fn_sort_by_header) {
      fn_sort_by_header(header_data.header_name)
    }
  }

  // select all / deselect all
  function selectAllHandler() {
    console.log("select all")
    if (fn_select_all) {
      fn_select_all()
    }
  }

  return (
    <>
      {header_data.header_key === "select_row" ? (
        <div className='table__header_select' onClick={selectAllHandler}>
          <FontAwesomeIcon icon={faSquare} />
        </div>
      ) : header_data.header_key === "edit_row" ? (
        <div className='table__header_select'>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      ) : header_data.header_key === "delete_row" ? (
        <div className='table__header_select'>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      ) : (
        <div
          className={`table__header_name ${
            header_data.cell_width
              ? `cell_width--${header_data.cell_width}`
              : ""
          }`}
        >
          <p className='table__header_name_text'>{headerName}</p>
          {header_data.is_sortable && (
            <div className='table__header_name_icon' onClick={sortHandler}>
              <FontAwesomeIcon
                icon={
                  sort_order === "asc"
                    ? faSortDown
                    : sort_order === "dsc"
                    ? faSortUp
                    : faSort
                }
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default TableHeader
