import { useEffect, useState } from "react"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import RadioGroup from "../../../global/components/RadioButton/RadioGroup"
import Input from "../../../global/components/input/Input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
/**
 * @param {object} input_data - pump data object
 * @param {function} fn_set_input_data - setter function for pump data object
 * @param {object} options_list_obj - pump options list object
 * @param {boolean} on_validation - validation flag
 * @param {function} fn_set_validation_obj - setter function for validation object
 * @returns {JSX.Element} - pump specifications JSX Element
 */
function PumpSpecs({
  input_data,
  fn_set_input_data,
  options_list_obj,
  on_validation,
  fn_set_validation_obj,
}) {
  // list of options for select components
  const { meters_list, units_list } = options_list_obj

  // end use data object
  const [endUseData, setEndUseData] = useState({})

  // open close details flag
  const [openDetails, setOpenDetails] = useState(true)

  useEffect(() => {
    if (input_data) {
      setEndUseData(input_data)
    }
  }, [input_data])

  function handleEndUseInputChange(newValue) {
    let end_use_name = endUseData.end_use_name
    let end_use_input_name = newValue.name
    let end_use_input_value = newValue.value

    if (end_use_input_name.includes(end_use_name)) {
      end_use_input_name = end_use_input_name.replace(end_use_name, "").slice(1)
    }
    let new_end_use_data = {
      ...endUseData,
      [end_use_input_name]: end_use_input_value,
    }
    fn_set_input_data({
      type: "update_end_use",
      payload: new_end_use_data,
    })
  }

  function handleOpenDetails() {
    setOpenDetails(!openDetails)
  }
  return (
    <div id='pumps'>
      <hr />
      {openDetails ? (
        <>
          <InstructionalText
            type='Main Instructions'
            title={`${endUseData.end_use_name} - Pumps Specifications`}
            content={
              <>Manually enter details about the pumps in this section.</>
            }
            containerClassName='main-instructions'
          />
          <div className='content'>
            <Input
              label='Water Flow'
              type='text'
              name='water_flow'
              onChange={handleEndUseInputChange}
              value={endUseData.water_flow}
              placeholder='0 GPM'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <RadioGroup
              legend='Variable Speed/Frequency?'
              name={`${endUseData.end_use_name}_variable_speed_or_frequency`}
              radioOptions={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
              onChange={handleEndUseInputChange}
              value={endUseData.variable_speed_or_frequency}
            />
            <div className='fill-in'></div>
          </div>
          <div className='content'>
            <div className='variable_speed_frequency_control_comment'>
              <Input
                label='Variable Speed/Frequency Control Comment'
                type='text'
                name={`${endUseData.end_use_name}_variable_speed_or_frequency_comment`}
                onChange={handleEndUseInputChange}
                value={endUseData.variable_speed_or_frequency_comment}
                placeholder='Enter your comments here...'
                className='default'
                disabled={endUseData.variable_speed_or_frequency === "No"}
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='content'>
            <div className='flex justify-end'>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon={faChevronUp} />
                <p className='end-use-details-button-text'>Hide Details</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='content'>
            <div className='flex justify-between'>
              <h2 className='end-use-title'>
                {`${endUseData.end_use_name} - Pumps Specifications`}
              </h2>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon={faChevronDown} />
                <p className='end-use-details-button-text'>Show Details</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default PumpSpecs
