import React, { useEffect, useState } from "react"
import "../../styles/profile.css"
import {
  validateAddress,
  validateCompanyName,
  validatePhone,
  validateStringName,
} from "../../utils/DataValidator"
import {
  changePasswordPost,
  changeUserDetailsPost,
} from "../../utils/HttpClient"
import TabContent from "./TabContent"
import TabNavItem from "./TabNavItem"
import TabNavItemMobile from "./TabNavItemMobile"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faKey, faPencil, faUser } from "@fortawesome/pro-light-svg-icons"

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState("tab1")
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    company: "",
    office_address: "",
    account_number: "",
  })
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    new_password_confirm: "",
  })

  const [errorFirstName, setFirstNameErrorMessage] = React.useState("")
  const [errorLastName, setLastNameErrorMessage] = React.useState("")
  const [errorPhone, setPhoneErrorMessage] = React.useState("")
  const [errorCompany, setCompanyErrorMessage] = React.useState("")
  const [errorOffice, setOfficeErrorMessage] = React.useState("")
  const [errorOldPassword, setOldPasswordError] = React.useState("")
  const [passwordTypeError, setPasswordTypeError] = React.useState("")
  const [activedHamburgerBtn, setActivedHamburgerBtn] = useState("active_menu")

  useEffect(() => {
    if (props.userData && props.userData.info) {
      setData({
        email: props.userData.info.email,
        first_name: props.userData.info.first_name,
        last_name: props.userData.info.last_name,
        phone: props.userData.info.phone,
        company: props.userData.info.company,
        office_address: props.userData.info.office_address,
        account_number: props.userData.info.account_number,
      })
    }
  }, [props])

  const renderTableDataRows = () => {
    return (
      <div className='user-info'>
        <div>
          First Name <p>{data.first_name} </p>
        </div>
        <div>
          Last Name
          <p>{data.last_name}</p>
        </div>
        <div>
          Company
          <p>{data.company}</p>
        </div>
        <div>
          Email
          <p>{data.email}</p>
        </div>
        <div>
          Phone
          <p>{data.phone}</p>
        </div>
        <div>
          Office Address
          <p>{data.office_address}</p>
        </div>
      </div>
    )
  }

  function handleChange(event) {
    const value = event.target.value
    setData({
      ...data,
      [event.target.name]: value,
    })
  }

  function handlePasswordChange(event) {
    const value = event.target.value
    setPassword({
      ...password,
      [event.target.name]: value,
    })
  }

  function errorsPresent(details) {
    let count = 0
    if (!validateStringName(details["first_name"])) {
      count++
      setFirstNameErrorMessage("Invalid First Name")
    } else if (details["first_name"] === "") {
      count++
      setFirstNameErrorMessage("First Name is required")
    } else {
      setFirstNameErrorMessage("")
    }

    if (!validateStringName(details["last_name"])) {
      count++
      setLastNameErrorMessage("Invalid Last Name")
    } else if (details["last_name"] === "") {
      count++
      setLastNameErrorMessage("Last Name is required")
    } else {
      setLastNameErrorMessage("")
    }

    if (!validatePhone(details["phone"])) {
      count++
      setPhoneErrorMessage("Invalid Phone Number")
    } else if (details["phone"] === "") {
      count++
      setPhoneErrorMessage("Phone Number is required")
    } else {
      setPhoneErrorMessage("")
    }

    // if (!validateCompanyName(details["company"])) {
    //   count++
    //   setCompanyErrorMessage("Invalid Company Name")
    // } else if (details["company"] === "") {
    //   count++
    //   setCompanyErrorMessage("Company Name is required")
    // } else {
    //   setCompanyErrorMessage("")
    // }

    // if (!validateAddress(details["office_address"])) {
    //   count++
    //   setOfficeErrorMessage("Invalid Office Address")
    // } else if (details["office_address"] === "") {
    //   count++
    //   setOfficeErrorMessage("Office Address is required")
    // } else {
    //   setOfficeErrorMessage("")
    // }

    return count !== 0
  }

  const updateProfileDetails = (e) => {
    e.preventDefault()

    if (errorsPresent(data)) {
      console.log("errors present")
    } else {
      changeUserDetailsPost(data)
        .then((res) => {
          alert("Update Success")
          window.location.reload(false)
        })
        .catch((err) => console.log(err))
    }
  }

  const updatePassword = (e) => {
    e.preventDefault()
    setOldPasswordError("")
    setPasswordTypeError("")

    changePasswordPost(password)
      .then((res) => {
        if (res.data.old_password_incorrect_error) {
          setOldPasswordError(res.data.old_password_incorrect_error)
          setTimeout(() => setOldPasswordError(""), 2000)
        } else if (res.data.match_new_passwords_error) {
          setPasswordTypeError(res.data.match_new_passwords_error)
          setTimeout(() => setPasswordTypeError(""), 2000)
        } else if (res.data.password_type_error) {
          setPasswordTypeError(res.data.password_type_error)
          setTimeout(() => setPasswordTypeError(""), 2000)
        } else if (!res.data.success) {
          console.log(res.data)
        } else {
          alert("Password Changed")
          window.location.reload(false)
        }
      })
      .catch((err) => console.log(err))
  }

  const [isEditProfileClicked, setIsEditProfileClicked] = useState(false)
  const [activeSidebarTab, setActiveSidebarTab] = useState("tab1");

  const handleEditProfileClick = () => {
    setIsEditProfileClicked(!isEditProfileClicked)
    setActiveTab("tab2")
    setActiveSidebarTab("tab2")
  }

  return (
    <div className='account-container'>
      {/* Desktop version */}

      <div className='desktop-box'>
        {/* sidebar - first */}
        <TabContent id='tab1' activeTab={activeTab}>
          <h2 className='section_heading_2 p-4'>Account Overview</h2>
          <div className='tabContainer'>
            {renderTableDataRows()}

            {/* new eidt btn */}
            <button
              className='account-edit-btn'
              onClick={handleEditProfileClick}
            >
              Edit
            </button>
          </div>
        </TabContent>
    
        {/* sidebar - second */}
        <TabContent id='tab2' activeTab={activeTab}>
          <h2 className='section_heading_2 p-4'>Edit Profile</h2>
          <div className='tabContainer editProfile'>
            <div className='textFieldContainer'>
              <label htmlFor='first_name'>First Name</label>
              <input
                id='first_name'
                type='text'
                name='first_name'
                onChange={handleChange}
                value={data.first_name}
                style={errorFirstName ? { border: "1px solid var(--error)" } : {}}
              />
              {errorFirstName && (
                <div className='error-message'>{errorFirstName}</div>
              )}
            </div>

            <div className='textFieldContainer'>
              <label htmlFor='last_name'>Last Name</label>

              <input
                id='last_name'
                name='last_name'
                type='text'
                onChange={handleChange}
                value={data.last_name}
                style={errorLastName ? { border: "1px solid var(--error)" } : {}}
              />
              {errorLastName && (
                <div className='error-message'>{errorLastName}</div>
              )}
            </div>

            <div className='textFieldContainer'>
              <label htmlFor='company'>Company</label>

              <input
                id='company'
                name='company'
                type='text'
                onChange={handleChange}
                value={data.company}
                style={errorCompany ? { border: "1px solid var(--error)" } : {}}
              />
              {errorCompany && (
                <div className='error-message'>{errorCompany}</div>
              )}
            </div>

            <div className='textFieldContainer'>
              <label htmlFor='email'>Email</label>

              <input
                id='email'
                name='email'
                type='text'
                onChange={handleChange}
                defaultValue={data.email}
                readOnly
              />
            </div>

            <div className='textFieldContainer'>
              <label htmlFor='phone'>Phone</label>

              <input
                id='phone'
                name='phone'
                onChange={handleChange}
                type='number'
                value={data.phone}
                style={errorPhone ? { border: "1px solid var(--error)" } : {}}
              />
              {errorPhone && <div className='error-message'>{errorPhone}</div>}
            </div>

            <div className='textFieldContainer'>
              <label htmlFor='office_address'>Office Address</label>

              <input
                id='office_address'
                type='text'
                name='office_address'
                onChange={handleChange}
                value={data.office_address}
                style={errorOffice ? { border: "1px solid var(--error)" } : {}}
              />
              {errorOffice && (
                <div className='error-message'>{errorOffice}</div>
              )}
            </div>

            {/* new save and cancel btns */}
            <div className='account-btn-box'>
              <button
                className='account-cancel-btn'
                onClick={() => {
                  setActiveTab("tab1")
                  setData({
                    ...data,
                    first_name: props.userData.info.first_name,
                    last_name: props.userData.info.last_name,
                    phone: props.userData.info.phone,
                    company: props.userData.info.company,
                    office_address: props.userData.info.office_address,
                  })
                  setPhoneErrorMessage("")
                  setCompanyErrorMessage("")
                  setOfficeErrorMessage("")
                  setFirstNameErrorMessage("")
                  setLastNameErrorMessage("")
                }}
              >
                Cancel
              </button>
              <button
                className='account-save-btn'
                onClick={updateProfileDetails}
              >
                Save Changes
              </button>
            </div>
          </div>
        </TabContent>

        {/* sidebar - 3rd */}
        <TabContent id='tab3' activeTab={activeTab}>
          <h2 className='section_heading_2 p-4'>Change Password</h2>
          <div className='tabContainer pswdBox'>
            <div className='textFieldContainer'>
              <label>Old Password</label>
              <input
                name='old_password'
                onChange={handlePasswordChange}
                type='password'
                placeholder='Enter Old Password'
                style={errorOldPassword ? { border: "1px solid var(--error)" } : {}}
              />
              {errorOldPassword && (
                <div className='error-message'>{errorOldPassword}</div>
              )}
            </div>

            <div className='textFieldContainer'>
              <label>New Password</label>

              <input
                name='new_password'
                onChange={handlePasswordChange}
                type='password'
                placeholder='Enter New Password'
                style={passwordTypeError ? { border: "1px solid var(--error)" } : {}}
              />
              {passwordTypeError && (
                <div className='error-message'>{passwordTypeError}</div>
              )}
            </div>

            <div className='textFieldContainer'>
              <label>Confirm New Password</label>

              <input
                name='new_password_confirm'
                onChange={handlePasswordChange}
                type='password'
                placeholder='Confirm New Password'
              />
              {/* {passwordMatches.length === 0
                && (
                    <div className='error-message'>{passwordMatches}</div>
                  )} */}

              {/* <TextField
                id='outlined'
                // error={passwordMatches.length === 0 ? false : true}
                // helperText={passwordMatches}
                name='new_password_confirm'
                onChange={handlePasswordChange}
                type='password'
              /> */}
            </div>

            {/* new save and cancel btns */}
            <div className='account-btn-box'>
              <button
                className='account-cancel-btn'
                onClick={() => {
                  setActiveTab("tab1")
                  setData({
                    ...data,
                    first_name: props.userData.info.first_name,
                    last_name: props.userData.info.last_name,
                    phone: props.userData.info.phone,
                    company: props.userData.info.company,
                    office_address: props.userData.info.office_address,
                  })
                }}
              >
                Cancel
              </button>
              <button className='account-save-btn' onClick={updatePassword}>
                Save Changes
              </button>
            </div>
          </div>
        </TabContent>
      </div>

      {/* sidebar */}
      <div className='hidden account-tabs md:block'>
        <h2 className='tabs-header'>Account Settings</h2>
        <div className='account-border'>
          <ul id='account-nav' className='nav'>
            <div>
              <TabNavItem
                item={<FontAwesomeIcon icon={faUser} className='tabs-icons' />}
                title='Account Overview'
                id='tab1'
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>

            <div>
              <TabNavItem
                item={
                  <FontAwesomeIcon icon={faPencil} className='tabs-icons' />
                }
                title='Edit Profile'
                id='tab2'
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setActiveSidebarTab={setActiveSidebarTab}
                activeSidebarTab={activeSidebarTab}
              />
            </div>

            <div>
              <TabNavItem
                item={<FontAwesomeIcon icon={faKey} className='tabs-icons' />}
                title='Change Password'
                id='tab3'
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </ul>
        </div>
      </div>

      {/* mobile */}
      <div className='account-tabs md:hidden'>
        <ul id='tab-container' className='flex report-tab nav-ul'>
          <div className='sections'>
            {
              {
                tab1: "Account Overview",
                tab2: "Edit Profile",
                tab3: "Change Password",
              }[activeTab]
            }
          </div>
          <div
            className={`${activedHamburgerBtn} ${
              activeTab === "tab1" ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <TabNavItemMobile
              title='Account Overview'
              id='tab1'
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div
            className={`${activedHamburgerBtn} ${
              activeTab === "tab2" ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <TabNavItemMobile
              title='Edit Profile'
              id='tab2'
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div
            className={`${activedHamburgerBtn} ${
              activeTab === "tab3" ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <TabNavItemMobile
              title='Change Password'
              id='tab3'
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div
            className='hambuger-icon'
            onClick={() => {
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              )
            }}
          >
            <i className='fa-solid fa-bars'></i>
          </div>
        </ul>
      </div>
    </div>
  )
}
export default Tabs
