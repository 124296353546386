import "./fileUploaderModal.css"
import { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-light-svg-icons"

function FileNameTag({
  isFolder,
  file_name,
  status,
  isEditable,
  onChange,
  onEdited,
  id,
}) {
  const [iconName, setIconName] = useState("")
  const icon_ref = useRef(null)
  const containerRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [fileName, setFileName] = useState("")

  useEffect(() => {
    setFileName(file_name)
  }, [file_name])

  useEffect(() => {
    if (status.includes("Accepted")) {
      setIconName(isFolder ? "folder" : "task")
      !isFolder && (icon_ref.current.style.color = "green")
    } else if (status.includes("Rejected") || status.includes("Error")) {
      setIconName(isFolder ? "folder" : "scan_delete")
      !isFolder && (icon_ref.current.style.color = "red")
    } else {
      setIconName(isFolder ? "folder" : "draft")
      icon_ref.current.style.color = "#112e4b"
    }
  }, [status])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (fileName !== "" && status === "Attached" && isEditing === false)
      onEdited(id, fileName, isFolder)
  }, [isEditing])

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsEditing(false)
    }
  }

  function handleOnChange(e) {
    setFileName(e.target.value)
    onChange(e.target.value, id, isFolder)
  }

  return (
    <div className='file-name__container' ref={containerRef}>
      <div className='file-name'>
        <span ref={icon_ref} className='material-symbols-outlined'>
          {iconName}
        </span>
        {isEditing ? (
          <>
            <input
              type='text'
              value={fileName}
              onChange={handleOnChange}
              className='file-name__input'
            />&nbsp;
          </>
        ) : (
          <p>{fileName}</p>
        )}
      </div>
      {status === "Attached" && (
        <button
          onClick={isEditable ? (e) => setIsEditing(!isEditing) : null}
          className={"name-edit__button" + (isEditable ? "" : "--disabled")}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      )}
    </div>
  )
}

export default FileNameTag
