import React, { useRef } from "react"
import { deleteEquipment } from "../../../utils/HttpClient"
import ModalDialog from "../../home/components/ModalDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons"

const DeleteEquipmentButton = (props) => {
  const modalRef = useRef(null)

  const openModal = () => {
    modalRef.current.showModal()
  }

  const closeModal = () => {
    modalRef.current.close()
  }

  // const handleDelete = () => {
  //   deleteEquipment(props.deleted_id, props.project_id).then((res) => {
  //     if (res.data.result === true) {
  //       alert("Successfully deleted equipment")
  //       props.onDeleted(props.deleted_id)
  //     } else {
  //       alert("Failed to delete equipment")
  //     }
  //     closeModal()
  //   })
  // }

  async function handleDelete() {
    return new Promise((resolve, reject) => {
      deleteEquipment(props.deleted_id, props.project_id)
        .then((res) => {
          if (res.data.result === true) {
            props.onDeleted(props.deleted_id)
            resolve({ error: false, message: "Successfully deleted equipment" })
            closeModal()
          } else {
            reject({ error: true, message: "Failed to delete equipment" })
          }
        })
        .catch((err) => {
          reject({ error: true, message: "Server Error" })
        })
    })
  }

  return (
    <>
      {/* Render the modal */}
      <ModalDialog
        modal_ref={modalRef}
        content_title='Delete Equipment?'
        content_body={`Do you want to delete equipment "${props.deleted_item_name}"?`}
        btn_confirm='Delete equipment'
        btn_cancel='Cancel'
        btn_close={true}
        fn_confirm={handleDelete}
      />
      {/* trash can icon */}
      <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={openModal}
        className='report_equipment_delete_button'
      />
    </>
  )
}

export default DeleteEquipmentButton
