import { useState, useEffect } from "react"
import "./select.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleExclamation,
  faCircleCheck,
  faBan,
  faArrowAltDown,
} from "@fortawesome/pro-light-svg-icons"
import Input from "../input/Input"

/*
Props:
- label: (String) Label for the select dropdown.
- name: (String) name attribute for the select element.
- value: (String/Array) Current selected value(s).
- options: (Array) List of options for the select dropdown. Can be a list of strings or objects for sub-options.
- onChange: (Function) Callback function when the value changes.
- placeholder: (String) Placeholder for the select dropdown.
- disabled: (Boolean) If true, the select element will be disabled.
- required: (Boolean) If true, the select element will be required.
- readOnly: (Boolean) If true, please set "disabled" to be true as well.
- error: (String) Custom error message for validation failure.
- validation (boolean): Indicates whether the MultiSelect should be validated, if true, the MultiSelect will display a validation icon and message.
- subOptions: (Boolean) Flag to indicate if the options are grouped (sub-options).
   -> If subOptions is false, then options is an array of strings
options = ["option1", "option2", "option3"]
   -> If subOptions is true, then options is an array of objects
options = [
  {category_name: "category1", property_types(category_options): [{name:"option1"}, {name:"option2"}, {name:"option3"}]},
  {category_name: "category2", property_types(category_options): [{name:"option4"}, {name:"option5"}, {name:"option6"}]},
]
- className: (String) Custom class for styling.
    -> "default": "select__field--default"
    -> "disabled": "select__field--disabled"
    -> "readonly": "select__field--readonly"


    // for arrow styling
    https://stackoverflow.com/questions/14218307/select-arrow-style-change

*/

const Select = ({
  label,
  name,
  value,
  options,
  subOptions, // boolean
  onChange,
  className = "default",
  placeholder,
  disabled,
  required,
  readOnly,
  error,
  validation,
  set_validation_object,
  addNewOption,
  validation_icon_only,
}) => {
  const [validationResult, setValidationResult] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value)
  const [optionsList, setOptionsList] = useState([])
  const [newOption, setNewOption] = useState("")
  const [inputMode, setInputMode] = useState(false)

  useEffect(() => {
    if (addNewOption) {
      if (value && options && options.length > 0) {
        if (options.includes(value)) {
          setOptionsList(options)
          setSelectedOption(value)
        } else {
          let options_list = [...options, value]
          setOptionsList(options_list)
          setSelectedOption(value)
        }
      } else if (options && options.length > 0) {
        setOptionsList(options)
        setSelectedOption(value)
      }
    } else {
      // if (value && options && options.length > 0) {
      //   setOptionsList(options)
      //   setSelectedOption(value)
      // } else
      if (options && options.length > 0) {
        setOptionsList(options)
        setSelectedOption(value)
      }
    }
  }, [value, options])

  useEffect(() => {
    if (selectedOption && selectedOption.length > 0) {
      if (selectedOption === "Custom: Enter Value") {
        setInputMode(true)
      } else {
        setInputMode(false)
      }
      setValidationResult(true)
    } else {
      setValidationResult(false)
    }
  }, [selectedOption])

  useEffect(() => {
    if (required && set_validation_object) {
      set_validation_object((prev) => {
        return { ...prev, [name]: validationResult }
      })
    }
  }, [validationResult])

  /**
   * - if selected newValue.value is 'Custom: Enter Value'
   * - render the input field, on change updates the newOption state ''
   * - if subOptions is true, then add the newOption to the Custom category
   * - if subOptions is false, then add the newOption to the optionsList
   *
   */

  const handleInputOnChange = (newValue) => {
    setNewOption(newValue.value)
  }

  const handleOnChange = (e) => {
    if (e.target.value !== "Custom: Enter Value") {
      onChange(e.target)
    }
    const { value, validity } = e.target
    setSelectedOption(value)
    setValidationResult(validity.valid ? true : false)
  }

  const renderOptions = (new_option_value, options_list) => {
    let all_options = [...options_list]

    if (addNewOption) {
      if (subOptions) {
        if (new_option_value.length > 0) {
          all_options = [
            ...all_options,
            {
              category_name: "Custom",
              property_types: [{ name: new_option_value }],
            },
          ]
        } else {
          all_options = [
            ...all_options,
            {
              category_name: "Custom",
              property_types: [{ name: "Custom: Enter Value" }],
            },
          ]
        }
      } else {
        if (new_option_value.length > 0) {
          all_options = [...all_options, new_option_value]
        } else {
          all_options = [...all_options, "Custom: Enter Value"]
        }
      }
    }

    if (subOptions) {
      return all_options.map((category, index) => (
        <optgroup key={index} label={category.category_name}>
          {category.property_types.map((subOption, subIndex) => (
            <option key={subIndex} value={subOption.name}>
              {subOption.name}
            </option>
          ))}
        </optgroup>
      ))
    } else {
      return all_options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))
    }
  }

  const renderIcon = () => {
    if (validation && required) {
      if (!validationResult) {
        return (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ color: "#CF0A2E" }}
            className='select__icon'
          />
        )
      } else if (!disabled && value) {
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#008611" }}
            className='select__icon'
          />
        )
      }
    }
    return null
  }

  const renderValidationMessage = () => {
    if (validation && required) {
      if (!validationResult) {
        return (
          <div className='select__valication select__validation--invalid'>
            {error ? error : `Please select a valid ${label}.`}
          </div>
        )
      } else if (validationResult && !disabled) {
        return (
          <div className='select__valication select__validation--valid'>
            {label} looks good!
          </div>
        )
      }
    }
    return null
  }

  const saveInputHandler = () => {
    // add to the list of options
    if (newOption.length > 0) {
      setOptionsList([...optionsList, newOption])
      onChange({ name: name, value: newOption })
      cancelInputHandler()
    }
  }

  const cancelInputHandler = () => {
    setNewOption("")
    setSelectedOption("")
    setInputMode(false)
  }

  return (
    <>
      <div className='select'>
        {label && (
          <label
            htmlFor={name}
            className={`select__label ${
              disabled && !readOnly
                ? "select__label--disabled"
                : "select__lable--default"
            }`}
          >
            {label}
            {label && required && (
              <span
                className={
                  disabled && !readOnly
                    ? "select__label--disabled"
                    : "select__label--required"
                }
              >
                *
              </span>
            )}
          </label>
        )}
        {inputMode ? (
          <div className='select__container'>
            <div className='select__container__input'>
              <div className='select__container__input_buttons'>
                <FontAwesomeIcon
                  className='select__container__input_buttons--save'
                  icon={faCircleCheck}
                  onClick={saveInputHandler}
                />
                <FontAwesomeIcon
                  className='select__container__input_buttons--cancel'
                  icon={faBan}
                  onClick={cancelInputHandler}
                />
              </div>
              <Input
                type='text'
                // pattern='^[a-zA-Z0-9\s]+$'
                name='new_option'
                onChange={handleInputOnChange}
                value={newOption}
                placeholder='Enter value'
                className='default'
              />
            </div>
          </div>
        ) : (
          <>
            <div className='select__container'>
              <select
                name={name}
                id={name}
                value={value}
                onChange={handleOnChange}
                className={`select__field select__field--${className}`}
                placeholder={placeholder}
                disabled={disabled || readOnly}
                required={required}
                data-touched={!disabled && !readOnly && validation && required}
              >
                {placeholder && ( //if value is empty, then show placeholder, else show value
                  <option value='' disabled>
                    {placeholder}
                  </option>
                )}
                {renderOptions(newOption, optionsList)}
                {/* <FontAwesomeIcon icon={faArrowAltDown} className='select__icon' /> */}
              </select>
              {renderIcon()}
            </div>
            {validation_icon_only ? null : renderValidationMessage()}
          </>
        )}
      </div>
    </>
  )
}

export default Select
