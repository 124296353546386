import { useEffect, useState, useRef } from "react"
import "./input.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleExclamation,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons"

/*
Props:
- label: (String) Text label for the input.
- type: (String) HTML input type, default is "text".
- name: (String) HTML name attribute.
- value: (String) Controlled value for the input.
- onChange: (Function) Function called when input changes.
- placeholder: (String) Placeholder text.
- icon: (React Element) FontAwesome icon, import from "@fortawesome/pro-light-svg-icons".
- iconColor: (String) Color for the icon (if any).
- iconPosition: (String) Position for the icon ('left' or 'right').
- pattern: (String) A Regex pattern for validation.
- validation (boolean): Indicates whether the MultiSelect should be validated, if true, should include the "pattern" in the prop, the MultiSelect will display a validation icon and message.
- min: (Number/String) Min value for types like "number" or "date".
- max: (Number/String) Max value for types like "number" or "date".
- disabled: (Boolean) Boolean to disable input.
- required: (Boolean) Boolean to mark input as required, adds a red asterisk to the label.
- readOnly: (Boolean) Boolean for read-only state.
- error: (String) Custom error message string, will be displayed below the input when the input is invalid.
- className: (String) Additional class names.
    -> "default": "input__field--default"
    -> "disabled": "input__field--disabled"
    -> "readonly": "input__field--readonly"
*/

/**
 * Problem: Input cursor jumping when editing existing value in controlled inputs
 * Solution: https://stackoverflow.com/a/68928267
 * use of setSelectionRange() to set the cursor position
 */

const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  className = "default",
  placeholder,
  icon,
  iconColor,
  iconPosition,
  pattern,
  min,
  max,
  disabled,
  required,
  readOnly,
  error,
  validation,
  set_validation_object,
  validation_icon_only,
}) => {
  const [validationResult, setValidationResult] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [inputType, setInputType] = useState("text")

  const input_ref = useRef(null)
  const [cursorPosition, setCursorPosition] = useState(0)

  useEffect(() => {
    setInputValue(value)
    checkValidity(value)
    if (input_ref && input_ref.current.type === "text") {
      input_ref.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [value, cursorPosition])

  useEffect(() => {
    if (type === "number") {
      setInputType("text")
    } else {
      setInputType(type)
    }
  }, [type])

  useEffect(() => {
    if (inputValue && inputValue.toString().length > 0) {
      checkValidity(inputValue)
    } else {
      setValidationResult(false)
    }
  }, [inputValue])

  useEffect(() => {
    if (required && set_validation_object) {
      set_validation_object((prev) => {
        return { ...prev, [name]: validationResult }
      })
    }
  }, [validationResult])

  function checkValidity(input_value) {
    if (type === "text") {
      if (pattern === undefined) pattern = "^.{1,200}$"
      const regex_default = new RegExp(pattern)
      setValidationResult(regex_default.test(input_value))
    } else if (type === "number") {
      const regex_number = new RegExp("^[0-9]*[.]?[0-9]*$")
      console.log("input_value", input_value)
      if (regex_number.test(input_value)) {
        let num_value = parseFloat(input_value)
        console.log("is number : num_value", num_value)
        if (min !== undefined || max !== undefined) {
          if (min !== undefined && max !== undefined)
            setValidationResult(num_value >= min && num_value <= max)
          else if (max !== undefined) setValidationResult(num_value <= max)
          else setValidationResult(num_value >= min)
        } else {
          setValidationResult(regex_number.test(num_value))
        }
      }
    }
  }

  const handleOnChange = (e) => {
    let { name, value } = e.target

    // remove all unacceptable characters
    // find index of . , if there is more than 1, then validation fails
    // if first index and last index are the same for '.' then validation is successful
    // otherwise the input is rejected

    if (type === "number") {
      let filtered_value = value.replace(/[^0-9.]/g, "")
      let first_dot_index = filtered_value.indexOf(".")
      let last_dot_index = filtered_value.lastIndexOf(".")
      if (first_dot_index === last_dot_index) {
        onChange({ name: name, value: filtered_value })
      }
    } else {
      onChange(e.target)
    }

    // console.log("value", value, "new_value", new_value)
    setCursorPosition(e.target.selectionStart)
    // if (new_value.length > 0) checkValidity(new_value)
  }

  const renderIcon = () => {
    if (validation && required) {
      if (!validationResult) {
        return (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ color: "#CF0A2E" }}
            className='input__icon input__icon--right'
          />
        )
      } else if (!disabled && value) {
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#008611" }}
            className='input__icon input__icon--right'
          />
        )
      }
    } else if (icon) {
      return (
        <FontAwesomeIcon
          icon={icon}
          style={{ color: iconColor }}
          className={`input__icon input__icon--${iconPosition}`}
        />
      )
    }
    return null
  }

  const renderValidationMessage = () => {
    if (validation && required) {
      if (!validationResult) {
        return (
          <div className='input__validation input__validation--invalid'>
            {error ? error : `Please provide a valid ${label}.`}
          </div>
        )
      } else if (validationResult && !disabled) {
        return (
          <div className='input__validation input__validation--valid'>
            {label} looks good!
          </div>
        )
      }
    }
    return null
  }

  return (
    <div className='input'>
      {label && (
        <label
          htmlFor={name}
          className={`input__label ${
            disabled ? "input__label--disabled" : "input__label--default"
          }`}
        >
          {label}
          {label && required && (
            <span
              className={
                disabled ? "input__label--disabled" : "input__label--required"
              }
            >
              *
            </span>
          )}
        </label>
      )}
      <div className='input__container'>
        <input
          ref={input_ref}
          type={inputType}
          id={name}
          name={name}
          value={value}
          onChange={handleOnChange}
          className={`input__field input__field--icon--${iconPosition}  ${validation ? (validationResult ? "input__field--valid" : "input__field--invalid") : `input__field--${className ? className : "default"}`}`}
          placeholder={placeholder}
          pattern={pattern}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          maxLength={200}
        />
        {renderIcon()}
      </div>
      {validation_icon_only ? null : renderValidationMessage()}
    </div>
  )
}

export default Input
