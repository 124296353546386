import { useEffect, useRef, useState } from "react"
import ActionButton from "./ActionButton"
import "../../../styles/importProperty/FileUploader.css"
import { fileOpen, directoryOpen } from "browser-fs-access"
import { importAuditReports } from "../../../utils/HttpClient"
import StatusMessage from "./StatusMessage"
import LinearProgress from "@material-ui/core/LinearProgress"
import FileDetailsTable from "./FileDetailsTable"
import UserStorageSpace from "../components/UserStorageSpace"

function FileUploader(props) {
  // loaded files
  const [files, setFiles] = useState([])
  const [errorFiles, setErrorFiles] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [tableData, setTableData] = useState([])

  const used_storage = props.user_data.current_usage_in_mb
  const total_storage = props.user_data.storage_quota_in_mb

  // result message
  const [resultMessage, setResultMessage] = useState({ msg: "", status: null })

  // file result list
  const [failedFileNames, setFailedFileNames] = useState([])
  const [passedFileNames, setPassedFileNames] = useState([])

  // flags
  const [receivedResponse, setReceivedResponse] = useState(false)
  const [loading, setLoading] = useState(false)

  // references
  const form_ref = useRef(null)
  const dropzone_ref = useRef(null)

  useEffect(() => {
    if (files.length > 0 || errorFiles.length > 0) {
      let total_size = files.reduce((total, file) => total + file.size, 0)
      setTotalSize(total_size)
      // genereate table list
      generateTableDataList()
    } else {
      setReceivedResponse(false)
      setTotalSize(0)
    }

    // check if all files are processed
    if (receivedResponse) {
      if (passedFileNames.length > 0 && failedFileNames.length > 0) {
        setResultMessage({
          msg_top: "Import completed with some errors!",
          msg_bot: "Click here to view the imported buildings.",
          status: "Error",
        })
      } else if (passedFileNames.length > 0 && failedFileNames.length === 0) {
        setResultMessage({
          msg_top: "Import completed!",
          msg_bot: "Click here to view the imported buildings.",
          status: "Accepted",
        })
      } else {
        setResultMessage({
          msg_top: "Import failed!",
          msg_bot: "Please check the rejected files",
          status: "Rejected",
        })
      }
    } else {
      setResultMessage({ msg_top: "", msg_bot: "", status: null })
    }
  }, [files, errorFiles, receivedResponse])

  // when file is dragged over the dropzone, prevent all default actions
  function handleDragOver(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  // when file is dropped, it will add to the existing filelist
  function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()

    setResultMessage({ msg: "", status: null })

    let file_objects = []
    let failed_blobs = []

    if (e.dataTransfer.files.length > 0) {
      let items = e.dataTransfer.items

      for (let item of items) {
        let item_entry = item.webkitGetAsEntry()

        if (item_entry.isFile) {
          let item_file = item.getAsFile()

          if (item_file.type !== "application/pdf") {
            failed_blobs.push({
              name: item_file.name,
              size: item_file.size,
              message: "File type not supported!",
              status: "Error",
            })
          } else if (item_file.size > 5000000) {
            failed_blobs.push({
              name: item_file.name,
              size: item_file.size,
              message: "File size exceeds 5 MB!",
              status: "Error",
            })
          } else {
            file_objects.push(item_file)
          }
        } else if (item_entry.isDirectory) {
          let item_file = item.getAsFile()
          failed_blobs.push({
            name: "/" + item_file.name,
            size: item_file.size,
            message: "Folder drop not supported!",
            status: "Error",
          })
        }
      }
    }

    if (receivedResponse) {
      setFiles([...file_objects])
      setErrorFiles([...failed_blobs])
    } else {
      setFiles([...files, ...file_objects])
      setErrorFiles([...errorFiles, ...failed_blobs])
    }
    setReceivedResponse(false)
  }

  function generateTableDataList() {
    let generated_file_list = []
    let new_projects_list = []

    if (errorFiles.length > 0) {
      for (let file of errorFiles) {
        generated_file_list.push(file)
      }
    }

    if (receivedResponse) {
      if (failedFileNames.length > 0) {
        for (let file_name of failedFileNames) {
          let file = files.find((file) => file.name === file_name.file_path)
          if (file) {
            generated_file_list.push({
              name: file.name,
              size: file.size,
              message: file_name.error,
              status: "Rejected",
            })
          }
        }
      }
      if (passedFileNames.length > 0) {
        for (let file_name of passedFileNames) {
          let file = files.find((file) => file.name === file_name.file_path)
          if (file) {
            generated_file_list.push({
              name: file.name,
              size: file.size,
              message: "Imported successfully!",
              status: "Accepted",
            })
            new_projects_list.push(file_name.project_number)
          }
        }
      }
    } else {
      if (files.length > 0) {
        for (let file of files) {
          generated_file_list.push({
            name: file.name,
            size: file.size,
            message: "File selected",
            status: "Selected",
          })
        }
      }
    }

    if (generated_file_list.length > 0) {
      let old_projects_list = JSON.parse(
        localStorage.getItem("new_projects_list")
      )
      if (old_projects_list) {
        new_projects_list = [...new_projects_list, ...old_projects_list]
      }
      setTableData([...generated_file_list])
      localStorage.setItem(
        "new_projects_list",
        JSON.stringify(new_projects_list)
      )
    }
  }

  function onSubmitHandler(e) {
    e.preventDefault()
    if (receivedResponse) {
      onResetHandler()
    } else {
      if (files.length > 0) {
        setLoading(true)
        // add files to form data
        let files_data = new FormData()
        for (let file of files) {
          files_data.append("pdf_reports", file, file.name)
        }
        // post to server
        importAuditReports(files_data)
          .then((res) => {
            setLoading(false)
            props.get_user_data()
            setFailedFileNames(res.failed_reports)
            setPassedFileNames(res.successful_reports)
            setReceivedResponse(true)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setPassedFileNames([])
            let failed_filenames = []
            for (let file of files) {
              failed_filenames.push({
                file_path: file.name,
                error: "Server Error!",
              })
            }
            setFailedFileNames(failed_filenames)
            setReceivedResponse(true)
          })
      }
    }
  }

  function onResetHandler(e) {
    setFiles([])
    setTableData([])
    setPassedFileNames([])
    setFailedFileNames([])
    setReceivedResponse(false)
    setErrorFiles([])
  }

  async function handleOpenFile(e) {
    await fileOpen({
      extensions: [".pdf"],
      multiple: true,
    })
      .then((blobs) => {
        let failed_blobs = []

        for (let i = 0; i < blobs.length; i++) {
          // limit file size to 5MB
          if (blobs[i].size > 5000000) {
            failed_blobs.push({
              name: blobs[i].name,
              size: blobs[i].size,
              message: "File size exceeds 5MB!",
              status: "Error",
            })
            blobs.splice(i, 1)
            i--
          }
        }

        if (receivedResponse) {
          setFiles([...blobs])
          setErrorFiles([...failed_blobs])
        } else {
          setFiles([...files, ...blobs])
          setErrorFiles([...errorFiles, ...failed_blobs])
        }

        setReceivedResponse(false)
      })
      .catch((err) => console.error(err))
  }

  async function handleOpenDirectory(e) {
    await directoryOpen({
      recursive: true,
    })
      .then((blobs) => {
        let failed_blobs = []
        for (let i = 0; i < blobs.length; i++) {
          // limit file size to 5MB
          if (blobs[i].size > 5000000) {
            failed_blobs.push({
              name: blobs[i].name,
              size: blobs[i].size,
              message: "File size exceeds 5MB!",
              status: "Error",
            })
            blobs.splice(i, 1)
            i--
            // remove non pdfs
          } else if (!blobs[i].type.includes("pdf")) {
            failed_blobs.push({
              name: blobs[i].name,
              size: blobs[i].size,
              message: "File type not supported!",
              status: "Error",
            })
            blobs.splice(i, 1)
            i--
          }
        }

        if (receivedResponse) {
          setFiles([...blobs])
          setErrorFiles([...failed_blobs])
        } else {
          setFiles([...files, ...blobs])
          setErrorFiles([...errorFiles, ...failed_blobs])
        }

        setReceivedResponse(false)
      })
      .catch((err) => console.error(err))
  }

  function removeRow(file_name) {
    let new_error_files = [...errorFiles]
    let selectedErrorFileIndex = new_error_files.findIndex(
      (file) => file.name === file_name
    )
    if (selectedErrorFileIndex !== -1) {
      new_error_files.splice(selectedErrorFileIndex, 1)
    }
    setErrorFiles([...new_error_files])

    let new_table_data = [...tableData]
    let selectedRowIndex = new_table_data.findIndex(
      (file) => file.name === file_name
    )
    if (selectedRowIndex !== -1) {
      new_table_data.splice(selectedRowIndex, 1)
    }
    setTableData([...new_table_data])

    let new_files = [...files]
    let selectedFileIndex = new_files.findIndex(
      (file) => file.name === file_name
    )
    if (selectedFileIndex !== -1) {
      new_files.splice(selectedFileIndex, 1)
    }
    setFiles([...new_files])
  }

  return (
    <div className='file-uploader-container'>
      <div className='file-uploader-header'>
        <div className='upload-buttons-container'>
          <button className='file-button-action' onClick={handleOpenFile}>
            Add Files
          </button>
          <button className='file-button-action-white' onClick={handleOpenDirectory}>
            Add Folder
          </button>
        </div>
        <div className='storage-indicator-container'>
          <UserStorageSpace
            used_storage={used_storage}
            total_storage={total_storage}
          />
        </div>
      </div>
      <form ref={form_ref} onSubmit={onSubmitHandler} onReset={onResetHandler}>
        <div
          className='file-dropzone'
          ref={dropzone_ref}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {tableData.length > 0 ? (
            <>
              <FileDetailsTable
                data_list={tableData}
                fn_remove_row={removeRow}
              />
            </>
          ) : (
            <div className='file-input-placeholder'>
              Drag and drop files here
            </div>
          )}
        </div>

        <div className='storage-container'>
          <div className='file-input-stats'>
            <p>Supported File Types: PDF</p>
            <p>Max. File Size: 5 MB</p>
          </div>
          <div className='file-input-stats'>
            {receivedResponse && <p>Accepted: {passedFileNames.length}</p>}
            {receivedResponse && <p>Rejected: {failedFileNames.length}</p>}
            {errorFiles.length > 0 && <p>Error: {errorFiles.length}</p>}
            <p>Selected: {files.length}</p>
            <p>
              Total Size:{" "}
              {totalSize > 1000000
                ? (totalSize / 1000000).toFixed(1) + " MB"
                : (totalSize / 1000).toFixed(1) + " kB"}
            </p>
          </div>
        </div>
        <div className='progress-bar-container'>
          {loading && (
            <div>
              <p className='progress-warning-message'>
                Importing may take a few minutes
              </p>
              <LinearProgress variant='indeterminate' />
            </div>
          )}
        </div>
        <div className='bottom-container'>
          <div className='status-message-container'>
            {receivedResponse && resultMessage.msg_top.length > 0 && (
              <StatusMessage
                completed={resultMessage.status}
                msg_top={resultMessage.msg_top}
                msg_bot={resultMessage.msg_bot}
                fn_close={setResultMessage}
              />
            )}
          </div>
          <div className='action-button-container'>
            <ActionButton type='submit' text='Import Audits' />
            <ActionButton type='reset' text='Remove All' theme='light' />
          </div>
        </div>
      </form>
    </div>
  )
}

export default FileUploader
