import React from "react"
import "./navigationTab.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons"

const NavigationTab = ({
  id,
  label,
  isSelected,
  onClick,
  disabled,
  iconPosition,
  rightIconState,
  className,
}) => {
  let rightIcon = null;

  if (rightIconState === "good") {
    rightIcon = <FontAwesomeIcon icon={faCheckCircle} />;
  } else if (rightIconState === "error") {
    rightIcon = <FontAwesomeIcon icon={faExclamationCircle} />;
  }

  return (
    <li
      className={`${className} navigationTab__small navigationTab__large ${isSelected ? "selected" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick}
      tabIndex={disabled ? -1 : 0}
      role='tab'
      aria-selected={isSelected}
      aria-disabled={disabled}
      aria-controls={id}
    >
      {!disabled && iconPosition === "left" && (
        <div className='navigationTab__left-icon'>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
      )}
      <div className={`navigationTab__label navigationTab-mobile__label`}>{label}</div>
      {!disabled && iconPosition === "right" && rightIcon && (
        <div
          className={`navigationTab__right-icon ${
            rightIconState === "good"
              ? ""
              : "navigationTab__right-icon--error"
          }`}
        >
          {rightIcon}
        </div>
      )}
    </li>
  )
}

export default NavigationTab
