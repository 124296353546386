import "./fileUploaderModal.css"
import { useState, useEffect } from "react"
import FileNameTag from "./FileNameTag"
import { ConsoleView } from "react-device-detect"

function FileDetailsTable({
  table_headers,
  data_list,
  set_data_list,
  set_attached_data_list,
  fn_remove_row,
  isEditable,
  fn_download_file,
  fn_edit_name,
  table_disabled,
}) {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setTableData(data_list)
  }, [data_list])

  function calculateFileSize(size) {
    if (size > 1000000) {
      return (size / 1000000).toFixed(1) + " MB"
    } else if (size > 1000) {
      return (size / 1000).toFixed(1) + " KB"
    } else {
      return size + " B"
    }
  }

  function handleNameChange(new_name, id, isFolder) {
    let new_table_data = [...tableData]
    if (isFolder) {
      new_table_data[id].folder_name = new_name
    } else {
      new_table_data[id].file_name = new_name
    }
    setTableData([...new_table_data])
    set_data_list([...new_table_data])
  }

  function handleOnDelete(index, file_name, status) {
    if (status === "Selected") {
      fn_remove_row(file_name)
      let new_table_data = [...tableData]
      new_table_data.splice(index, 1)
      setTableData(new_table_data)
      if (set_attached_data_list) {
        set_attached_data_list(new_table_data)
      } else {
        set_data_list(new_table_data)
      }
    } else {
      fn_remove_row(index)
    }
  }

  return (
    <div className='file-details-table__container'>
      <table
        className={`file-details-table ${
          table_disabled && "file-details-table--disabled"
        }`}
      >
        <thead className='file-details__thead'>
          <tr>
            {table_headers?.map((header, index) => {
              // let formatted_header = header.replace(/_/g, " ")
              return (
                <th key={index}>
                  {header
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className='file-details__tbody'>
          {tableData?.length > 0 ? (
            tableData.map((file, index) => (
              <tr key={index}>
                {table_headers?.map((header, headerIndex) => {
                  const cellData = file[header]
                  return (
                    <td key={headerIndex}>
                      {header === "folder_name" ? (
                        cellData ? (
                          <FileNameTag
                            file_name={cellData}
                            status={file.status}
                            isFolder={header === "folder_name"}
                            isEditable={true}
                            onChange={handleNameChange}
                            onEdited={fn_edit_name}
                            id={index}
                          />
                        ) : (
                          "_"
                        )
                      ) : header === "file_name" ? (
                        <FileNameTag
                          file_name={cellData}
                          status={file.status}
                          isFolder={header === "folder_name"}
                          isEditable={true}
                          onChange={handleNameChange}
                          onEdited={fn_edit_name}
                          id={index}
                        />
                      ) : header === "file_size" ? (
                        calculateFileSize(cellData)
                      ) : header === "message" ? (
                        cellData
                      ) : header === "status" ? (
                        cellData
                      ) : header === "download" ? (
                        <span
                          className='material-symbols-outlined download-row__button'
                          onClick={
                            fn_download_file
                              ? (e) => fn_download_file(file)
                              : null
                          }
                        >
                          download
                        </span>
                      ) : header === "delete" ? (
                        <span
                          className='material-symbols-outlined delete-row__button'
                          onClick={(e) =>
                            handleOnDelete(index, file.file_name, file.status)
                          }
                        >
                          delete
                        </span>
                      ) : (
                        cellData
                      )}
                    </td>
                  )
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={table_headers.length} className='empty-table-note'>
                No files uploaded
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
export default FileDetailsTable
