/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { Sidebar } from "./pages/sidebar"
import { Home } from "./pages/home"
import { Portfolio } from "./pages/portfolio"
import { Budget } from "./pages/analysis/budget"
import { Report } from "./pages/report"
import { Profile } from "./pages/account"
import { ForgotPassword } from "./pages/forgotPassword"
import { ResetPassword } from "./pages/resetPassword"
import SignIn from "./pages/signIn"
import { Multiauth } from "./pages/signIn/Multiauth"
import Phonelogin from "./pages/signIn/Phonelogin"
import "./styles/index.css"
import { getData, getECMTableData } from "./utils/HttpClient"
import useCache from "./utils/UseCache"
import useToken from "./utils/UseToken"
import { DashboardPage } from "./pages/admin/dashboardPage/"
import { BuildingsPage } from "./pages/admin/buildings/"
import { RatePage } from "./pages/admin/rates/RatePage"
import { AddProviderElectricityRatePage } from "./pages/admin/rates/AddProviderElectricityRatePage"
import { EditProviderElectricityRatePage } from "./pages/admin/rates/EditProviderElectricityRatePage"
import { AddProviderElectricityBasicChargeRatePage } from "./pages/admin/rates/AddProviderElectricityBasicChargeRatePage"
import { EditProviderElectricityBasicChargeRatePage } from "./pages/admin/rates/EditProviderElectricityBasicChargeRatePage"
import { AddCityElectricityRatePage } from "./pages/admin/rates/AddCityElectricityRatePage"
import { EditCityElectricityRatePage } from "./pages/admin/rates/EditCityElectricityRatePage"
import { AddProviderGasRatePage } from "./pages/admin/rates/AddProviderGasRatePage"
import { EditProviderGasRatePage } from "./pages/admin/rates/EditProviderGasRatePage"
import { AddProviderGasBasicChargeRatePage } from "./pages/admin/rates/AddProviderGasBasicChargeRatePage"
import { EditProviderGasBasicChargeRatePage } from "./pages/admin/rates/EditProviderGasBasicChargeRatePage"
import { AddCityGasRatePage } from "./pages/admin/rates/AddCityGasRatePage"
import { EditCityGasRatePage } from "./pages/admin/rates/EditCityGasRatePage"
import UsersIndexPage from "./pages/admin/users/UsersIndexPage"
import UsersPage from "./pages/admin/users/manageUsers/UsersPage"
import UserProjectsPage from "./pages/admin/users/manageUsers/UserProjectsPage"
import ProjectsPage from "./pages/admin/users/manageProjects/ProjectsPage"
import ProjectUsersPage from "./pages/admin/users/manageProjects/ProjectUsersPage"
import LoadingSpinner from "./pages/global/LoadingSpinner"
import AnalysisLandingPage from "./pages/analysis"
import Bundle from "./pages/analysis/bundle"
import Decarb from "./pages/analysis/decarb"
import RetroFit from "./pages/analysis/retrofit"
import ImportProperty from "./pages/importProperty"
import AddProperty from "./pages/addProperty"
import PropertyFormPage from "./pages/addProperty/PropertyFormPage"
import DecarbPlans from "./pages/admin/decarbPlans"
import EspmSettings from "./pages/admin/espmSettings"
import PowerBI from "./pages/powerbi"
import DataHandler from "./pages/dataHandler"
// import DataSurvey from "./pages/dataHandler/DataSurvey"

import { b2cPolicies, protectedResources, loginRequest } from "./authConfig"
import { EventType } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"

function App() {
  const { token, removeToken, setToken } = useToken()
  const { getCache, clearCacheData } = useCache()
  const [role, setRole] = useState(null)
  const [data, setData] = useState({
    all_data: {},
    user: null,
    projects: [],
  })
  const [isLoading, setIsLoading] = useState(false)

  const { instance } = useMsal()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== "/pbi_login") {
      let signUpSignInFlowRequest = {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        scopes: [
          ...protectedResources.apiTodoList.scopes.read,
          ...protectedResources.apiTodoList.scopes.write,
        ],
      }
      // handle auth redired/do all initial setup for msal
      instance
        .handleRedirectPromise()
        .then((authResult) => {
          setIsLoading(true)
          // Check if user signed in
          const activeAccount = instance.getActiveAccount()
          if (!activeAccount) {
            // redirect anonymous user to login page
            instance.loginRedirect(signUpSignInFlowRequest)
          } else {
            instance
              .acquireTokenSilent({
                ...loginRequest,
                account: activeAccount,
              })
              .then((response) => {
                setToken(response.accessToken)
              })
              .catch((error) => {
                console.error(error)
                // alert(error);
                instance.loginRedirect(signUpSignInFlowRequest)
              })
          }
        })
        .catch((err) => {
          console.log(err)
          // alert(err);
          instance.loginRedirect(signUpSignInFlowRequest)
        })
    }
  }, [])

  function compareIssuingPolicy(idTokenClaims, policyToCompare) {
    let tfpMatches =
      idTokenClaims.hasOwnProperty("tfp") &&
      idTokenClaims["tfp"].toLowerCase() === policyToCompare.toLowerCase()
    let acrMatches =
      idTokenClaims.hasOwnProperty("acr") &&
      idTokenClaims["acr"].toLowerCase() === policyToCompare.toLowerCase()
    return tfpMatches || acrMatches
  }

  useEffect(() => {
    const localPolicies = b2cPolicies
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            localPolicies.names.editProfile
          )
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                compareIssuingPolicy(
                  account.idTokenClaims,
                  localPolicies.names.signUpSignIn
                )
            )

          let signUpSignInFlowRequest = {
            authority: localPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          }

          // silently login again with the signUpSignIn policy
          instance
            .ssoSilent(signUpSignInFlowRequest)
            .then(() => {
              console.log("AUTH_SERVICE | ACQUIRE_TOKEN_SSOSILENT:SUCCESS")
            })
            .catch((error) => {
              console.log(
                "AUTH_SERVICE | ACQUIRE_TOKEN_SSOSILENT:ERROR = ",
                error
              )
            })
        }
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  const onReportUpgradesRecEnergySolutionsDataChange = (
    project_number,
    newDataArray
  ) => {
    getECMTableData()
      .then((res) => {
        data.all_data.analysis.ecm_analysis_table = res
        setData(data)
      })
      .catch((err) => console.log(err))
  }

  function getUserData() {
    setIsLoading(true)
    if (token && token !== "" && token !== undefined) {
      getData("user", removeToken, clearCacheData, navigate)
        .then((res) => {
          setData(res)
          setRole(res?.user?.user_role)
          setIsLoading(false)
          localStorage.setItem("user_email", res.user.email)
        })
        .catch((err) => console.log(err))
    } else {
      setData({
        all_data: {},
        user: null,
        projects: [],
      })
      setRole(null)
      removeToken()
      navigate("/")
    }
  }

  useEffect(() => {
    {
      /*
      Tokens are assigned when a user is authenticated
        1. validation is handled by functions in the httpClient
        2. tokens are removed on logout
        3. when a valid token is assigned we get the user's data
        4. when data is pulled, get and assign the user's role
    */
    }
    getUserData()
  }, [token])
  return (
    <>
      {data && token && token !== "" && token !== undefined ? (
        <>
          {(() => {
            {
              /* Display different components depending on role */
            }
            switch (role) {
              case "admin":
                return (
                  <>
                    {
                      // ADMIN PAGE
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        data={data.user}
                        userRole={role}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />
                      <div id='sub-page-admin' className='w-full'>
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <DashboardPage data={data.all_data.admin} />
                            }
                          />
                          <Route
                            path='/buildings'
                            element={
                              <BuildingsPage
                                data={data.all_data.admin?.buildings}
                                clearCacheData={clearCacheData}
                                getUserData={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/decarbPlans'
                            element={
                              <DecarbPlans
                                data={data.all_data.admin.buildings}
                                user_data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/espmSettings'
                            element={<EspmSettings />}
                          />
                          <Route path='/users' element={<UsersIndexPage />} />
                          <Route
                            path='/users/manage-users'
                            element={
                              <UsersPage
                                data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/users/manage-users/user-projects'
                            element={
                              <UserProjectsPage getUserData={getUserData} />
                            }
                          />
                          <Route
                            path='/users/manage-projects'
                            element={<ProjectsPage data={data.user} />}
                          />
                          <Route
                            path='/users/manage-projects/project-users'
                            element={
                              <ProjectUsersPage getUserData={getUserData} />
                            }
                          />

                          <Route
                            path='/rates'
                            element={
                              <RatePage data={data.all_data.admin?.rates} />
                            }
                          />
                          <Route
                            path='/rates/provider/electricity/add'
                            element={<AddProviderElectricityRatePage />}
                          />
                          <Route
                            path='/rates/provider/electricity/edit'
                            element={<EditProviderElectricityRatePage />}
                          />
                          <Route
                            path='/rates/provider/electricity/basiccharge/add'
                            element={
                              <AddProviderElectricityBasicChargeRatePage />
                            }
                          />
                          <Route
                            path='/rates/provider/electricity/basiccharge/edit'
                            element={
                              <EditProviderElectricityBasicChargeRatePage />
                            }
                          />
                          <Route
                            path='/rates/city/electricity/add'
                            element={<AddCityElectricityRatePage />}
                          />
                          <Route
                            path='/rates/city/electricity/edit'
                            element={<EditCityElectricityRatePage />}
                          />
                          <Route
                            path='/rates/provider/gas/add'
                            element={<AddProviderGasRatePage />}
                          />
                          <Route
                            path='/rates/provider/gas/edit'
                            element={<EditProviderGasRatePage />}
                          />
                          <Route
                            path='/rates/provider/gas/basiccharge/add'
                            element={<AddProviderGasBasicChargeRatePage />}
                          />
                          <Route
                            path='/rates/provider/gas/basiccharge/edit'
                            element={<EditProviderGasBasicChargeRatePage />}
                          />
                          <Route
                            path='/rates/city/gas/add'
                            element={<AddCityGasRatePage />}
                          />
                          <Route
                            path='/rates/city/gas/edit'
                            element={<EditCityGasRatePage />}
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                          <Route
                            path='/pbi_reports'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              case "importer":
                return (
                  <>
                    {
                      // IMPORTER PAGE
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        userRole={role}
                        data={data.user}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />

                      <div
                        id='sub-page-owner'
                        // className='w-full lg:p-10'
                      >
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <Home
                                data={data.all_data.home}
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/portfolio'
                            element={
                              <Portfolio
                                data={data.all_data.portfolio}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis'
                            element={<AnalysisLandingPage />}
                          />

                          <Route
                            path='/analysis/budget'
                            element={<Budget data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/analysis/bundle'
                            element={
                              <Bundle
                                data={data.all_data.analysis}
                                buildings_table_data={
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/analysis/decarb'
                            element={
                              <Decarb
                                data={data.all_data.analysis}
                                user_data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis/retrofit'
                            element={<RetroFit data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/addProperty'
                            element={
                              <AddProperty
                                data={data.all_data}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/addProperty/form'
                            element={
                              <PropertyFormPage
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/addProperty/form/*'
                            element={
                              <PropertyFormPage
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/importProperty'
                            element={
                              <ImportProperty
                                all_data={data}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                          <Route
                            path='/report/*'
                            element={
                              <Report
                                removeToken={removeToken}
                                clearCacheData={clearCacheData}
                                onReportUpgradesRecEnergySolutionsDataChange={
                                  onReportUpgradesRecEnergySolutionsDataChange
                                }
                                tableData={
                                  data.all_data.home &&
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/pbi_reports'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              case "owner":
                return (
                  <>
                    {
                      // OWNER PAGE
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        userRole={role}
                        data={data.user}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />

                      <div
                        id='sub-page-owner'
                        // className='w-full lg:p-10'
                      >
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <Home
                                data={data.all_data.home}
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/portfolio'
                            element={
                              <Portfolio
                                data={data.all_data.portfolio}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis'
                            element={<AnalysisLandingPage />}
                          />

                          <Route
                            path='/analysis/budget'
                            element={<Budget data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/analysis/bundle'
                            element={
                              <Bundle
                                data={data.all_data.analysis}
                                buildings_table_data={
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/analysis/decarb'
                            element={
                              <Decarb
                                data={data.all_data.analysis}
                                user_data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis/retrofit'
                            element={<RetroFit data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                          <Route
                            path='/report/*'
                            element={
                              <Report
                                removeToken={removeToken}
                                clearCacheData={clearCacheData}
                                onReportUpgradesRecEnergySolutionsDataChange={
                                  onReportUpgradesRecEnergySolutionsDataChange
                                }
                                tableData={
                                  data.all_data.home &&
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/pbi_reports'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              case "vsm_viewer":
                return (
                  <>
                    {
                      // 'vsm_viewer'
                      // Client facing Virtual Sub-Metering Viewer role
                      // Overview, Analysis, Report
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        userRole={role}
                        data={data.user}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />

                      <div
                        id='sub-page-owner'
                        // className='w-full lg:p-10'
                      >
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <Home
                                data={data.all_data.home}
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/analysis'
                            element={<AnalysisLandingPage />}
                          />

                          <Route
                            path='/analysis/budget'
                            element={<Budget data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/analysis/bundle'
                            element={
                              <Bundle
                                data={data.all_data.analysis}
                                buildings_table_data={
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/analysis/decarb'
                            element={
                              <Decarb
                                data={data.all_data.analysis}
                                user_data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis/retrofit'
                            element={<RetroFit data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                          <Route
                            path='/report/*'
                            element={
                              <Report
                                removeToken={removeToken}
                                clearCacheData={clearCacheData}
                                onReportUpgradesRecEnergySolutionsDataChange={
                                  onReportUpgradesRecEnergySolutionsDataChange
                                }
                                tableData={
                                  data.all_data.home &&
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/pbi_reports'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              case "fresco_viewer":
                return (
                  <>
                    {
                      // 'fresco_viewer'
                      // FRESCo Viewer role
                      // Report
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        userRole={role}
                        data={data.user}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />

                      <div
                        id='sub-page-owner'
                        // className='w-full lg:p-10'
                      >
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              case "dev":
                return (
                  <>
                    {
                      // 'dev'
                      // Client facing Virtual Sub-Metering Viewer role
                      // Overview, Analysis, Report
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        userRole={role}
                        data={data.user}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />

                      <div
                        id='sub-page-owner'
                        // className='w-full lg:p-10'
                      >
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <Home
                                data={data.all_data.home}
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/portfolio'
                            element={
                              <Portfolio
                                data={data.all_data.portfolio}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis'
                            element={<AnalysisLandingPage />}
                          />

                          <Route
                            path='/analysis/budget'
                            element={<Budget data={data.all_data.analysis} />}
                          />
                          <Route
                            path='/analysis/bundle'
                            element={
                              <Bundle
                                data={data.all_data.analysis}
                                buildings_table_data={
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/analysis/decarb'
                            element={
                              <Decarb
                                data={data.all_data.analysis}
                                user_data={data.user}
                                get_user_data={getUserData}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/analysis/retrofit'
                            element={<RetroFit data={data.all_data.analysis} />}
                          />

                          <Route
                            path='/dataHandler'
                            element={
                              <DataHandler
                                data={data.all_data}
                                get_user_data={getUserData}
                              />
                            }
                          />

                          {/* <Route
                            path='/dataHandler/dataSurvey'
                            element={
                              <DataSurvey
                                data={data.all_data}
                                get_user_data={getUserData}
                              />
                            }
                          /> */}
                          <Route
                            path='/report/*'
                            element={
                              <Report
                                removeToken={removeToken}
                                clearCacheData={clearCacheData}
                                onReportUpgradesRecEnergySolutionsDataChange={
                                  onReportUpgradesRecEnergySolutionsDataChange
                                }
                                tableData={
                                  data.all_data.home &&
                                  data.all_data.home.table_data
                                }
                              />
                            }
                          />
                          <Route
                            path='/importProperty'
                            element={
                              <ImportProperty
                                all_data={data}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/pbi_reports'
                            element={
                              <PowerBI
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
              default:
                return (
                  <>
                    {
                      // VIEWER PAGE
                    }
                    <div className='main-page'>
                      <Sidebar
                        removeToken={removeToken}
                        data={data.user}
                        userRole={role}
                        projects={data.projects}
                        clearCacheData={clearCacheData}
                      />
                      <div id='sub-page-viewer'>
                        {/* Modal Loading Spinner */}
                        <LoadingSpinner is_loading={isLoading} />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <Home
                                data={data.all_data?.home}
                                user_data={data.user}
                                get_user_data={getUserData}
                              />
                            }
                          />
                          <Route
                            path='/portfolio'
                            element={
                              <Portfolio
                                data={data.all_data?.portfolio}
                                clearCacheData={clearCacheData}
                              />
                            }
                          />
                          <Route
                            path='/profile'
                            element={
                              <Profile
                                removeToken={removeToken}
                                info={data.user}
                              />
                            }
                          />
                          <Route
                            path='/report/*'
                            element={
                              <Report
                                removeToken={removeToken}
                                clearCacheData={clearCacheData}
                                onReportUpgradesRecEnergySolutionsDataChange={
                                  onReportUpgradesRecEnergySolutionsDataChange
                                }
                                tableData={
                                  data.all_data?.home &&
                                  data.all_data?.home.table_data
                                }
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </>
                )
            }
          })()}
        </>
      ) : (
        <Routes>
          <Route
            path='/'
            exact
            element={<LoadingSpinner is_loading={true} />}
          />
        </Routes>
      )}
    </>
  )
}

export default App
