import { useEffect, useState, useRef } from "react"
import ModalDialog from "./ModalDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/pro-light-svg-icons"
import "../../../styles/modalDialog.css";

function RemovePropertyDialog(props) {
  const [buildingName, setBuildingName] = useState("")

  const modal_ref = useRef(null)

  useEffect(() => {
    setBuildingName(props.building_name)
  }, [props.building_name])

  function handleOpen() {
    modal_ref.current.showModal()
  }

  function handleConfirm() {
    return props.fn_remove_building([props.project_number])
  }

  return (
    <div>
      <div className='remove-property-dialog' onClick={handleOpen}>
        {/* <span className='material-symbols-outlined'>delete</span> */}
        <FontAwesomeIcon icon={faTrashCan} className='delete-font-awesome' />
        <p>Delete</p>
      </div>
      <ModalDialog
        content_title={"Delete Building?"}
        content_body={
          <>
            Deleting this property will result in
            the loss of all saved analysis data associated with {buildingName}.
            <br />
            Are you sure you want to delete
            <span className='dialog-building-name'> {buildingName} </span>
            from your account?
          </>
        }
        btn_confirm={"Delete Building"}
        btn_cancel={"Cancel"}
        btn_close={true}
        fn_confirm={handleConfirm}
        fn_close={props.get_user_data}
        modal_ref={modal_ref}
      />
    </div>
  )
}

export default RemovePropertyDialog
